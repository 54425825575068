import React, { useEffect, useState } from "react";
import { apiClient } from "src/dependencies/api-client";
import { isDevEnv } from "src/utils/dev-env-helper";

type AuthOrRedirectProps = {
  children: JSX.Element;
};

export function AuthOrRedirect(props: AuthOrRedirectProps) {
  const [renderChildren, setRenderChildren] = useState(false);
  const [renderRedirect, setRenderRedirect] = useState(false);

  const devEnvironments = process.env.NODE_ENV !== "production";
  const preventRedirect = devEnvironments;

  useEffect(() => {
    setRenderChildren(false);
    setRenderRedirect(false);

    // NOTE: This if statement will be removed on build by webpack
    // if (process.env.NODE_ENV === "development") {
    //   console.warn("skipping checkHealth");
    //   setRenderChildren(true);
    //   return;
    // }

    apiClient
      .checkHealth()
      .then(() => {
        setRenderChildren(true);
      })
      .catch((err: Error) => {
        setRenderRedirect(true);
      });
  }, [preventRedirect]);

  if (renderChildren) {
    return props.children;
  }

  if (renderRedirect) {
    if (process.env.NODE_ENV == "development") {
      return (
        <h1>
          Auth missing or invalid - not redirecting you because this is local dev. Sign in and put your token in
          `accessToken` in localStorage
        </h1>
      );
    }

    // Make login page load properly in test env when viewing with bypasscorp
    const bypasscorp = new URL(window.location.href).searchParams.get("bypasscorp");
    const params = bypasscorp ? `?bypasscorp=${bypasscorp}` : "";
    // TODO: Redirect to correct url from HVH Auth (need mapping to get the correct domain)
    const targetURL = `/app${params}#/login?redirectUrl=${encodeURIComponent(window.location.href)}`;

    if (isDevEnv()) {
      console.warn(`Not authorized, redirecting to login URL '${targetURL}'`);
    }
    window.location.href = targetURL;
  }
  return <div></div>;
}
