import React, { useCallback, useEffect, useState } from "react";
import { APP_STATE, ConnectWidgetData } from "src/components/types";
import ConnectLanding from "src/components/ConnectLanding";
import CallEnded from "src/components/VNHE/CallEnded";
import { AppointmentError } from "./AppointmentError";
import { useTranslation } from "react-i18next";
import { ScanQR } from "src/components/RVP/ScanQR";
import { hasCallEnded } from "src/utils/urls";
import { PreCheckDevice } from "./PreCheckDevice";
import { RVPLandingPage } from "src/components/RVP/RVPLandingPage";

const RvpRoot = () => {
  const [appState, setAppState] = useState<APP_STATE>(APP_STATE.INIT);
  const [widgetData, setWidgetData] = useState<ConnectWidgetData | null>(null);
  const isCallEnded = hasCallEnded();
  const { t } = useTranslation();

  useEffect(() => {
    if (widgetData) setAppState(APP_STATE.WAITING_ROOM);
  }, [widgetData]);

  const renderContent = useCallback(() => {
    switch (appState) {
      case APP_STATE.INIT:
        document.title = t("VHNE-Document-Title-2");
        return <RVPLandingPage onContinue={() => setAppState(APP_STATE.QR)} />;
      case APP_STATE.QR:
        document.title = t("VHNE-Document-Title-2");
        return <ScanQR onStartCall={setWidgetData} />;
      case APP_STATE.WAITING_ROOM:
        document.title = t("VHNE-Document-Title-2");
        return <PreCheckDevice onContinue={() => setAppState(APP_STATE.START_RVP)} connectWidgetData={widgetData!} />;
      case APP_STATE.START_RVP:
        document.title = t("VHNE-Document-Title-3");
        return <ConnectLanding connectWidgetData={widgetData!} />;
      default:
        return <AppointmentError />;
    }
  }, [appState, widgetData]);

  if (isCallEnded) {
    document.title = t("VHNE-Document-Title-4");

    return <CallEnded rvp={true} />;
  }

  return renderContent();
};

export default RvpRoot;
