import { getAdobeEndpointFromDomain } from "src/config/locale-config";
import { getAdobeSessionId } from "src/utils/cookie";

export const ADOBE_DATA_LAYER_URL_NON_PROD =
  "https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js";
export const ADOBE_DATA_LAYER_URL_PROD = "https://assets.adobedtm.com/launch-EN6bc43e7eee5146beaa43d868366aabe6.min.js";

export interface MetricsWindow extends Window {
  dataLayerArray?: any[];
}

const adobeSessionId = getAdobeSessionId();

const pushLayer = (window: MetricsWindow, metric: any) => {
  if (process.env.NODE_ENV === "development") {
    console.log("Emit adobe event with payload:", JSON.stringify(metric, null, 2));
  }
  window.dataLayerArray = window.dataLayerArray || [];
  window.dataLayerArray.push(metric);
};

export function addEventMetric(
  window: MetricsWindow,
  applicationId: string,
  locale: string,
  event: string,
  props?: any
) {
  const metric = {
    application: {
      ID: applicationId,
    },
    page: {
      locale,
    },
    event,
    candidate: {
      adobeSessionId,
    },
    timeStamp: { time: new Date() },
    ...props,
  };
  pushLayer(window, metric);
}

export const initAdobeAnalytic = async () => {
  const script = document.createElement("script");
  script.setAttribute("src", getAdobeEndpointFromDomain());
  script.setAttribute("defer", "");
  script.setAttribute("async", "");
  document.body.appendChild(script);
};
