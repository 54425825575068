import { ConnectWidget } from "./../components/types";
import { rest } from "msw";

export const MSW_URL = "https://example.com";

export const checkHealth = rest.get(`${MSW_URL}/api/health`, (req, res, ctx) => {
  return res(ctx.status(200), ctx.delay(150));
});

const agendaPublicText = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Sample Public HTML Text</title>
  </head>
  <body>
    <header>
      <h1>Welcome to Virtual NHE landing page!</h1>
    </header>

    <main>
      <section id="about">
        <h2>About Us</h2>
        <p>
          We are a team of passionate individuals dedicated to providing
          high-quality services to our customers
        </p>
      </section>
    </main>

    <footer>
      <p>&copy; 2023 Sample Public HTML Text. All rights reserved.</p>
    </footer>
  </body>
</html>
`;

export const DUMMY_WIDGET_DATA: ConnectWidget = {
  data: {
    connectSourceUrl: "https://d1ddlw7p7j6hta.cloudfront.net/amazon-connect-chat-interface-client.js",
    elementId: "82080fbb-7833-4284-85d0-78676518967f",
    snippetId:
      "QVFJREFIZ2ZySXd1eE1EWFNRRkRQSWFXZjRvaGxjT0xmd1pOWWViVU4vd0hnZnByS1FGc3BQRFBhTXVMOXJkMDlDSXduUDFTQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNbVlaYmxYVDRDNExUVU0vM0FnRVFnQ3ZlODcxOEk3NGlnV1M4cUZiWUU4cXBGTXpWZkx2UWdyWlgxQVNoZ0RFcitFMHdyQ1g4L1ZOSWJZMHY6OjlIZGU4K1dMalVCdmZsNGFZais5OHM1Q3NyL1MzdG9YeFhRQk5sWEllRHhldThqVkkrVzFCUWhUNFYzUndpQW1OemdYaWxYZXdlNElBek5ZU29QNWh0UE5vdCtzMDIzN1RWbnlURHpQeEFWZnRDZHFySGlDZWNZWlhZVFdxakg5Q3BJK1QwenJ5VG9hZm8rODZPTWJPc0ZVK3JKd0YyZz0=",
    encodedToken: localStorage.getItem("mockEncodedToken")!,
    agendaPublicText: agendaPublicText,
  },
  // error: {
  //   cause: "TOO_EARLY",
  //   appointmentStartTimestamp: "2099-03-22T15:00:00.000Z",
  //   displayReadyTimeSlot: "10:00 am to 10:30 am",
  //   displayTimeZone: "America/Chicago",
  // },
};

export const rumProxy = rest.all(`${MSW_URL}/api/rum-proxy/*`, async (req, res, ctx) => {
  req.url.port = "9000";
  const realResponse = await ctx.fetch(req);
  return res(realResponse ? ctx.json(realResponse) : ctx.status(204));
});

export const getWidgetData = rest.get(`${MSW_URL}/api/connect/widget-details/*`, (req, res, ctx) => {
  return res(ctx.json(DUMMY_WIDGET_DATA), ctx.delay(1000), ctx.status(200));
});

export const handlers = [checkHealth, getWidgetData];
