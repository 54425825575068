import { getHvhLocale } from "src/utils/cookie";
import { ADOBE_DATA_LAYER_URL_NON_PROD, ADOBE_DATA_LAYER_URL_PROD } from "./../dependencies/adobe-analytic";
import * as hvhLocaleConfig from "./hvh-locale-configuration-value-global.json";

const patchedConfig = { ...hvhLocaleConfig, GB: hvhLocaleConfig.UK } as const;

type HvhLocaleConfig = typeof patchedConfig;

export type HvhCountry = keyof HvhLocaleConfig;

export function getLocale() {
  const countryConfig = patchedConfig[detectCountryFromDomain()];
  const cookieLocale = getHvhLocale();

  if (cookieLocale && cookieLocale in countryConfig.locales) {
    return cookieLocale;
  }

  return countryConfig["default-locale"];
}

export function getDefaultLocale(country: HvhCountry) {
  return patchedConfig[country]["default-locale"];
}

export function detectCountryFromDomain(): HvhCountry {
  switch (window.location.hostname) {
    // UK has GB for its ISO 2-letter country code
    case "gamma-uk.devo.jobsatamazon.hvh.a2z.com":
    case "uk.preprod.jobsatamazon.hvh.a2z.com":
    case "gamma.devo.jobsatamazon.hvh.a2z.com":
    case "www.jobsatamazon.co.uk":
      return "GB";
    // US
    case "beta-us.devo.jobsatamazon.hvh.a2z.com":
    case "gamma-us.devo.jobsatamazon.hvh.a2z.com":
    case "us.preprod.jobsatamazon.hvh.a2z.com":
    case "hiring.amazon.com":
      return "US";
    // BR
    case "gamma-br.devo.jobsatamazon.hvh.a2z.com":
    case "br.preprod.jobsatamazon.hvh.a2z.com":
    case "trabalho.amazon.com.br":
      return "BR";
    // CO
    case "gamma-co.devo.jobsatamazon.hvh.a2z.com":
    case "co.preprod.jobsatamazon.hvh.a2z.com":
    case "trabaja.amazon.co":
      return "CO";
    // CR
    case "gamma-cr.devo.jobsatamazon.hvh.a2z.com":
    case "cr.preprod.jobsatamazon.hvh.a2z.com":
    case "trabaja.amazon.cr":
      return "CR";
    // MX
    case "gamma-mx.devo.jobsatamazon.hvh.a2z.com":
    case "mx.preprod.jobsatamazon.hvh.a2z.com":
    case "trabaja.amazon.com.mx":
      return "MX";
    // CA
    case "gamma-ca.devo.jobsatamazon.hvh.a2z.com":
    case "ca.preprod.jobsatamazon.hvh.a2z.com":
    case "hiring.amazon.ca":
      return "CA";
    // IN Swayam
    case "gamma-in.devo.jobsatamazon.hvh.a2z.com":
    case "in.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.hvhindia.in":
      return "IN";
    // IN CS
    case "gamma-in-cs.devo.jobsatamazon.hvh.a2z.com":
    case "in-cs.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.in":
      return "IN";
    // JP
    case "gamma-jp.devo.jobsatamazon.hvh.a2z.com":
    case "jp.preprod.jobsatamazon.hvh.a2z.com":
    case "shigoto.amazon.co.jp":
      return "JP";
    // ZA
    case "gamma-za.devo.jobsatamazon.hvh.a2z.com":
    case "za.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.co.za":
      return "ZA";
    // IE
    case "gamma-ie.devo.jobsatamazon.hvh.a2z.com":
    case "ie.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.ie":
      return "IE";
    // PH
    case "gamma-ph.devo.jobsatamazon.hvh.a2z.com":
    case "ph.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.ph":
      return "PH";
    // JO
    case "gamma-jo.devo.jobsatamazon.hvh.a2z.com":
    case "jo.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.jo":
      return "JO";
    // EG
    case "gamma-eg-cs.devo.jobsatamazon.hvh.a2z.com":
    case "eg.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.eg":
      return "EG";
    default:
      return "US";
  }
}

export function getAdobeEndpointFromDomain() {
  switch (window.location.hostname) {
    case "www.jobsatamazon.co.uk":
    case "hiring.amazon.com":
    case "trabalho.amazon.com.br":
    case "trabaja.amazon.co":
    case "trabaja.amazon.cr":
    case "trabaja.amazon.com.mx":
    case "hiring.amazon.ca":
    case "jobs.hvhindia.in":
    case "jobs.amazon.in":
    case "shigoto.amazon.co.jp":
    case "jobs.amazon.co.za":
    case "jobs.amazon.ie":
    case "jobs.amazon.ph":
    case "jobs.amazon.jo":
    case "jobs.amazon.eg":
      return ADOBE_DATA_LAYER_URL_PROD;
    default:
      return ADOBE_DATA_LAYER_URL_NON_PROD;
  }
}
