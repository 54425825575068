import { ConnectWidgetData } from "src/components/types";

const parseJson = (s: string) => {
  let ret = null;
  try {
    ret = JSON.parse(s);
  } catch (error) {
    console.error(error);
    ret = null;
  }
  return ret;
};

const isValidQR = (qr: Record<string, string> | null) => {
  const keys = ["u", "e", "s", "t"];
  if (!qr || !Object.keys(qr)) return false;
  return keys.reduce((acc, key) => {
    if (!acc) return false;
    return key in qr;
  }, true);
};

const remapKeys = (qr: Record<string, string>): ConnectWidgetData => {
  return {
    connectSourceUrl: qr.u,
    elementId: qr.e,
    snippetId: qr.s,
    encodedToken: qr.t,
  };
};

export const getQR = (s: string) => {
  const data = parseJson(s);
  if (isValidQR(data)) return remapKeys(data);
  return null;
};
