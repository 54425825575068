import React from "react";
import { Col } from "@amzn/stencil-react-components/dist/submodules/layout";
import { useCheckViewport } from "src/hooks/useCheckViewport";
import { CSSValue } from "@amzn/stencil-react-components/dist/submodules/utils";

interface PageContainerProps {
  children: React.ReactNode;
  withoutPadding?: boolean;
  maxWidth?: CSSValue;
}

const PageContainer = ({ children, withoutPadding = false, maxWidth }: PageContainerProps) => {
  const { isLargeViewport } = useCheckViewport();

  return withoutPadding ? (
    <Col
      width={"100%"}
      gridGap="2rem"
      padding={isLargeViewport ? "1rem 1rem 4rem 1rem" : "0rem 0rem 4rem 0rem"}
      data-testid="app-wrapper"
    >
      {children}
    </Col>
  ) : (
    <Col
      maxWidth={maxWidth || isLargeViewport ? "1200px" : "900px"}
      margin={"auto"}
      width={"100%"}
      padding={isLargeViewport ? "1rem 1rem 4rem 1rem" : "0rem 0rem 4rem 0rem"}
      data-testid="app-wrapper"
    >
      {children}
    </Col>
  );
};

export default PageContainer;
