import React from "react";
import { useBreakpoints } from "@amzn/stencil-react-components/dist/submodules/responsive";

export const useCheckViewport = () => {
  const {
    matches: { m: isMediumViewport, s: isSmallViewport },
  } = useBreakpoints();

  const isLargeViewport = !isSmallViewport && !isMediumViewport;

  return { isSmallViewport, isMediumViewport, isLargeViewport };
};
