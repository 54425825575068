import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { View } from "@amzn/stencil-react-components/dist/submodules/layout";
import { useCheckViewport } from "src/hooks/useCheckViewport";
import { Text } from "@amzn/stencil-react-components/dist/submodules/text";
import moment from "moment";
import { isDevEnv } from "src/utils/dev-env-helper";

interface GenericErrorProps {
  error: string;
}

export const GenericError = ({ error }: GenericErrorProps) => {
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
  }, []);

  if (isDevEnv()) {
    console.error("ERROR when tried to load the page: " + error);
  }

  const { t } = useTranslation();
  const { isSmallViewport } = useCheckViewport();

  return (
    <View style={{ maxWidth: 572, height: "100%", margin: isSmallViewport ? 0 : "auto" }} data-testid="generic-error">
      <Text id="page-title" ref={titleRef} tabIndex={-1}>
        {t("VNHE-Error-General")}
      </Text>
    </View>
  );
};
