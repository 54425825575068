import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/dist/submodules/button";
import { H1, Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { Flex, View } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { getLocale } from "src/config/locale-config";
import { addEventMetric, MetricsWindow } from "src/dependencies/adobe-analytic";
import PageWithIcon from "../Layout/PageWithIcon";
import Bg2 from "../../assets/bg2.svg";
import { AppointmentType } from "../types";
import { useAppointmentUrl, useChecklistUrl, useRvpStartUrl } from "src/hooks/useUrl";

const eventPageLoad = "page load of 'Your call has ended'";
const eventStartVideoCall = "click of 'Start video call' on call ended page";
const eventSeeNextSteps = "click of 'See next steps' on call ended page";
const eventRVPRedirect = "RVP call ended redirect";
interface CallEndedProps {
  rvp?: boolean;
}
const CallEnded = ({ rvp }: CallEndedProps) => {
  const { applicationId, appointmentType, jobId } =
    useParams<{
      applicationId: string;
      appointmentType: string;
      jobId: string;
    }>();
  const { t } = useTranslation();
  const titleRef = useRef<HTMLHeadingElement>(null);
  const rvpStartUrl = useRvpStartUrl();
  const checklistUrl = useChecklistUrl({ applicationId: applicationId!, jobId: jobId! });
  const appointmentUrl = useAppointmentUrl({
    applicationId: applicationId!,
    jobId: jobId!,
    appointmentType: appointmentType!,
  });

  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
  }, []);

  const locale = getLocale();

  useEffect(() => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventPageLoad);
  }, []);

  const handleStartVideoCall = () => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventStartVideoCall);
    window.location.replace(appointmentUrl);
  };

  const handleSeeNextStep = () => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventSeeNextSteps);
    window.location.replace(checklistUrl);
  };

  const handleRVPRedirect = () => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventRVPRedirect);
    setTimeout(() => {
      window.location.replace(rvpStartUrl);
    }, 2000);
  };

  if (rvp) {
    handleRVPRedirect();
    return (
      <View>
        <H1 id="page-title" ref={titleRef} tabIndex={-1}>
          {t("VNHE-CallEnded-Title")}
        </H1>
      </View>
    );
  }

  if (appointmentType === AppointmentType.INSTANT_NEW_HIRE_EVENT) {
    return (
      <PageWithIcon src={Bg2}>
        <Flex flexDirection="column" rowGap={10} justifyContent="flex-start" alignItems="flex-start">
          <H1 id="instant-call-ended-title" ref={titleRef} tabIndex={-1}>
            {t("VNHE-CallEnded-Title1")}
          </H1>
          <Text>{t("VNHE-CallEnded-Description1")}</Text>
          <Text>{t("VNHE-CallEnded-Description2")}</Text>
          <Text>{t("VNHE-CallEnded-Description3")}</Text>
          <Flex flexDirection="row" margin={{ top: 20 }}>
            <Button onClick={handleStartVideoCall} size={ButtonSize.Small} variant={ButtonVariant.Secondary}>
              {t("VNHE-CallEnded-Try-Again-ButtonLabel")}
            </Button>
            <Button
              onClick={handleSeeNextStep}
              size={ButtonSize.Small}
              variant={ButtonVariant.Secondary}
              style={{ marginLeft: 10 }}
            >
              {t("VNHE-CallEnded-SeeNextStep-ButtonLabel")}
            </Button>
          </Flex>
        </Flex>
      </PageWithIcon>
    );
  }

  return (
    <View>
      <H1 id="page-title" ref={titleRef} tabIndex={-1}>
        {t("VNHE-CallEnded-Title")}
      </H1>
      <Flex flexDirection="row" margin={{ top: 20 }}>
        <Button onClick={handleStartVideoCall} size={ButtonSize.Small} variant={ButtonVariant.Secondary}>
          {t("VNHE-CallEnded-StartVideoCall-ButtonLabel")}
        </Button>
        <Button
          onClick={handleSeeNextStep}
          size={ButtonSize.Small}
          variant={ButtonVariant.Secondary}
          style={{ marginLeft: 10 }}
        >
          {t("VNHE-CallEnded-SeeNextStep-ButtonLabel")}
        </Button>
      </Flex>
    </View>
  );
};

export default CallEnded;
