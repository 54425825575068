import { html, property, svg } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import {
  isPageDirectionRightToLeft,
  offHtmlDirChange,
  onHtmlDirChange,
} from '../../utils/rtl-utils';
import baseStyles from '../../shared/base/base.lit.scss';
import { ifNotNull } from '../../utils/directives';
import { NAME_TO_CODE_POINT } from './name-to-code-point';
import { NAME_TO_CODE_POINT_FOR_RTL } from './name-to-code-point-rtl';
import { iconsToFlipHorizantly } from './icons-names-to-flip-horizantly';
import { getFlagUrl } from './name-to-flag';
import styles from './icon.lit.scss';

const stringsFromCodePoints = {};

// Note, also add these to enum jsdoc comment below
const customSvgIcons = {
  all_filters: svg`<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9997 16L11.9979 6.66666H9.334L9.33301 15.9998C9.7446 15.8727 10.1946 15.8027 10.666 15.8027C11.1377 15.8027 11.5879 15.8728 11.9997 16ZM11.9979 25.3333L11.9997 21.3333C11.5879 21.4632 11.1377 21.5347 10.666 21.5347C10.1946 21.5347 9.7446 21.4633 9.33301 21.3336L9.334 25.3333H11.9979Z" fill="none"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.667 18.6667C14.667 16.4575 12.8761 14.6667 10.667 14.6667C8.45785 14.6667 6.66699 16.4575 6.66699 18.6667C6.66699 20.8758 8.45785 22.6667 10.667 22.6667C12.8761 22.6667 14.667 20.8758 14.667 18.6667ZM8.66699 18.6667C8.66699 17.5621 9.56242 16.6667 10.667 16.6667C11.7716 16.6667 12.667 17.5621 12.667 18.6667C12.667 19.7712 11.7716 20.6667 10.667 20.6667C9.56242 20.6667 8.66699 19.7712 8.66699 18.6667Z" fill="none"/><path fill-rule="evenodd" clip-rule="evenodd" d="M20.0003 16L20.0021 25.3333L22.666 25.3333L22.667 16.0002C22.2554 16.1273 21.8054 16.1973 21.334 16.1973C20.8623 16.1973 20.4121 16.1272 20.0003 16ZM20.0021 6.66668L20.0003 10.6667C20.4121 10.5368 20.8623 10.4653 21.334 10.4653C21.8054 10.4653 22.2554 10.5367 22.667 10.6664L22.666 6.66668L20.0021 6.66668Z" fill="none"/><path fill-rule="evenodd" clip-rule="evenodd" d="M17.333 13.3333C17.333 15.5425 19.1239 17.3333 21.333 17.3333C23.5421 17.3333 25.333 15.5425 25.333 13.3333C25.333 11.1242 23.5421 9.33334 21.333 9.33334C19.1239 9.33334 17.333 11.1242 17.333 13.3333ZM23.333 13.3333C23.333 14.4379 22.4376 15.3333 21.333 15.3333C20.2284 15.3333 19.333 14.4379 19.333 13.3333C19.333 12.2288 20.2284 11.3333 21.333 11.3333C22.4376 11.3333 23.333 12.2288 23.333 13.3333Z" fill="none"/>`,
  unsorted: svg`<path d="M16 12L17.5667 13.5667L21.1111 10.0333V26.2222H23.3333V10.0333L26.8667 13.5778L28.4444 12L22.2222 5.77777L16 12Z" fill="none"/><path d="M16.4443 20L14.8777 18.4333L11.3332 21.9666L11.3332 5.77776L9.111 5.77776L9.111 21.9666L5.57767 18.4222L3.99989 20L10.2221 26.2222L16.4443 20Z" fill="none"/>`,
  expand: svg`<path d="M17.3846 21L16.3 19.825L19.0609 16.834L14 16.8333V15.1667L19.0618 15.167L16.3 12.175L17.3846 11L22 16L17.3846 21Z" fill="none"/><path d="M12.67 21H11V11H12.67V21Z" fill="none"/>`,
  sparkle: svg`<g clip-path="url(#clip0_4067_12142)"><path d="M25.452 12.6924L27.3961 8.44167L31.6731 6.50948L27.3961 4.57731L25.452 0.326538L23.5079 4.57731L19.2309 6.50948L23.5079 8.44167L25.452 12.6924ZM16.6566 15.4433L12.7684 6.94172L8.88023 15.4433L0.326172 19.3076L8.88023 23.1719L12.7684 31.6735L16.6566 23.1719L25.2107 19.3076L16.6566 15.4433Z" fill="none"/></g><defs><clipPath id="clip0_4067_12142"><rect width="32" height="32" fill="white"/></clipPath></defs>`,
};

/**
 * @component {kat-icon} KatalIcon Icons are images that serve as a form of visual shorthand to reinforce behaviors for common actions or concepts. KDS uses the filled icons from the <a href="https://material.io/resources/icons/?style=baseline" target="_blank">Material design system</a>.
 * @guideline Do Use icons inform/help the customer with decision making or reinforce a concept.
 * @guideline Do Large size - applicable for placing icons in content areas that group like-functionality and/or content at a category level
 * @guideline Do Small size - applicable for placing icons in toolbars or touch interfaces
 * @guideline Do xsmall size - applicable for question mark and information icons in space-constrained design approaches
 * @example Info {"name": "info", "size": "large"}
 * @example Warning {"name": "warning", "size": "large"}
 * @example ChevronDown {"name": "chevron-down", "size": "large"}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-icon')
export class KatIcon extends KatLitElement {
  /**
   * Name of the icon
   * @enum {value} ac_unit ac_unit icon
   * @enum {value} access_alarm access_alarm icon
   * @enum {value} access_alarms access_alarms icon
   * @enum {value} access_time access_time icon
   * @enum {value} accessibility accessibility icon
   * @enum {value} accessible accessible icon
   * @enum {value} account_balance account_balance icon
   * @enum {value} account_balance_wallet account_balance_wallet icon
   * @enum {value} account_box account_box icon
   * @enum {value} account_circle account_circle icon
   * @enum {value} adb adb icon
   * @enum {value} add add icon
   * @enum {value} add_a_photo add_a_photo icon
   * @enum {value} add_alarm add_alarm icon
   * @enum {value} add_alert add_alert icon
   * @enum {value} add_box add_box icon
   * @enum {value} add_circle add_circle icon
   * @enum {value} add_circle_outline add_circle_outline icon
   * @enum {value} add_location add_location icon
   * @enum {value} add_shopping_cart add_shopping_cart icon
   * @enum {value} add_to_photos add_to_photos icon
   * @enum {value} add_to_queue add_to_queue icon
   * @enum {value} adjust adjust icon
   * @enum {value} airline_seat_flat airline_seat_flat icon
   * @enum {value} airline_seat_flat_angled airline_seat_flat_angled icon
   * @enum {value} airline_seat_individual_suite airline_seat_individual_suite icon
   * @enum {value} airline_seat_legroom_extra airline_seat_legroom_extra icon
   * @enum {value} airline_seat_legroom_normal airline_seat_legroom_normal icon
   * @enum {value} airline_seat_legroom_reduced airline_seat_legroom_reduced icon
   * @enum {value} airline_seat_recline_extra airline_seat_recline_extra icon
   * @enum {value} airline_seat_recline_normal airline_seat_recline_normal icon
   * @enum {value} airplanemode_active airplanemode_active icon
   * @enum {value} airplanemode_inactive airplanemode_inactive icon
   * @enum {value} airplanemode_off airplanemode_off icon
   * @enum {value} airplanemode_on airplanemode_on icon
   * @enum {value} airplay airplay icon
   * @enum {value} airport_shuttle airport_shuttle icon
   * @enum {value} alarm alarm icon
   * @enum {value} alarm_add alarm_add icon
   * @enum {value} alarm_off alarm_off icon
   * @enum {value} alarm_on alarm_on icon
   * @enum {value} album album icon
   * @enum {value} alert alert icon
   * @enum {value} alert-circle alert-circle icon
   * @enum {value} alert-circle-fill alert-circle-fill icon
   * @enum {value} alert-fill alert-fill icon
   * @enum {value} all_filters all_filters icon
   * @enum {value} all_inclusive all_inclusive icon
   * @enum {value} all_out all_out icon
   * @enum {value} android android icon
   * @enum {value} announcement announcement icon
   * @enum {value} aperture aperture icon
   * @enum {value} apps apps icon
   * @enum {value} archive archive icon
   * @enum {value} arrow-down arrow-down icon
   * @enum {value} arrow-left arrow-left icon
   * @enum {value} arrow-right arrow-right icon
   * @enum {value} arrow-up arrow-up icon
   * @enum {value} arrow_back arrow_back icon
   * @enum {value} arrow_downward arrow_downward icon
   * @enum {value} arrow_drop_down arrow_drop_down icon
   * @enum {value} arrow_drop_down_circle arrow_drop_down_circle icon
   * @enum {value} arrow_drop_up arrow_drop_up icon
   * @enum {value} arrow_forward arrow_forward icon
   * @enum {value} arrow_upward arrow_upward icon
   * @enum {value} art_track art_track icon
   * @enum {value} aspect_ratio aspect_ratio icon
   * @enum {value} assessment assessment icon
   * @enum {value} assignment assignment icon
   * @enum {value} assignment_ind assignment_ind icon
   * @enum {value} assignment_late assignment_late icon
   * @enum {value} assignment_return assignment_return icon
   * @enum {value} assignment_returned assignment_returned icon
   * @enum {value} assignment_turned_in assignment_turned_in icon
   * @enum {value} assistant assistant icon
   * @enum {value} assistant_photo assistant_photo icon
   * @enum {value} attach_file attach_file icon
   * @enum {value} attach_money attach_money icon
   * @enum {value} attachment attachment icon
   * @enum {value} audiotrack audiotrack icon
   * @enum {value} autorenew autorenew icon
   * @enum {value} av_timer av_timer icon
   * @enum {value} backspace backspace icon
   * @enum {value} backup backup icon
   * @enum {value} barcode barcode icon
   * @enum {value} battery_alert battery_alert icon
   * @enum {value} battery_charging_full battery_charging_full icon
   * @enum {value} battery_full battery_full icon
   * @enum {value} battery_std battery_std icon
   * @enum {value} battery_unknown battery_unknown icon
   * @enum {value} beach_access beach_access icon
   * @enum {value} beenhere beenhere icon
   * @enum {value} block block icon
   * @enum {value} bluetooth bluetooth icon
   * @enum {value} bluetooth_audio bluetooth_audio icon
   * @enum {value} bluetooth_connected bluetooth_connected icon
   * @enum {value} bluetooth_disabled bluetooth_disabled icon
   * @enum {value} bluetooth_searching bluetooth_searching icon
   * @enum {value} blur_circular blur_circular icon
   * @enum {value} blur_linear blur_linear icon
   * @enum {value} blur_off blur_off icon
   * @enum {value} blur_on blur_on icon
   * @enum {value} book book icon
   * @enum {value} bookmark bookmark icon
   * @enum {value} bookmark_border bookmark_border icon
   * @enum {value} bookmark_outline bookmark_outline icon
   * @enum {value} border_all border_all icon
   * @enum {value} border_bottom border_bottom icon
   * @enum {value} border_clear border_clear icon
   * @enum {value} border_color border_color icon
   * @enum {value} border_horizontal border_horizontal icon
   * @enum {value} border_inner border_inner icon
   * @enum {value} border_left border_left icon
   * @enum {value} border_outer border_outer icon
   * @enum {value} border_right border_right icon
   * @enum {value} border_style border_style icon
   * @enum {value} border_top border_top icon
   * @enum {value} border_vertical border_vertical icon
   * @enum {value} box box icon
   * @enum {value} branding_watermark branding_watermark icon
   * @enum {value} brightness_auto brightness_auto icon
   * @enum {value} brightness_five brightness_five icon
   * @enum {value} brightness_four brightness_four icon
   * @enum {value} brightness_high brightness_high icon
   * @enum {value} brightness_low brightness_low icon
   * @enum {value} brightness_medium brightness_medium icon
   * @enum {value} brightness_one brightness_one icon
   * @enum {value} brightness_seven brightness_seven icon
   * @enum {value} brightness_six brightness_six icon
   * @enum {value} brightness_three brightness_three icon
   * @enum {value} brightness_two brightness_two icon
   * @enum {value} broken_image broken_image icon
   * @enum {value} brush brush icon
   * @enum {value} bubble_chart bubble_chart icon
   * @enum {value} bug_report bug_report icon
   * @enum {value} build build icon
   * @enum {value} burst_mode burst_mode icon
   * @enum {value} business business icon
   * @enum {value} business_center business_center icon
   * @enum {value} cached cached icon
   * @enum {value} cake cake icon
   * @enum {value} calendar calendar icon
   * @enum {value} calendar-alt calendar-alt icon
   * @enum {value} call call icon
   * @enum {value} call_end call_end icon
   * @enum {value} call_made call_made icon
   * @enum {value} call_merge call_merge icon
   * @enum {value} call_missed call_missed icon
   * @enum {value} call_missed_outgoing call_missed_outgoing icon
   * @enum {value} call_received call_received icon
   * @enum {value} call_split call_split icon
   * @enum {value} call_to_action call_to_action icon
   * @enum {value} camera camera icon
   * @enum {value} camera-fill camera-fill icon
   * @enum {value} camera_alt camera_alt icon
   * @enum {value} camera_enhance camera_enhance icon
   * @enum {value} camera_filter camera_filter icon
   * @enum {value} camera_front camera_front icon
   * @enum {value} camera_rear camera_rear icon
   * @enum {value} camera_roll camera_roll icon
   * @enum {value} cancel cancel icon
   * @enum {value} card_giftcard card_giftcard icon
   * @enum {value} card_membership card_membership icon
   * @enum {value} card_travel card_travel icon
   * @enum {value} casino casino icon
   * @enum {value} cast cast icon
   * @enum {value} cast_connected cast_connected icon
   * @enum {value} center_focus_strong center_focus_strong icon
   * @enum {value} center_focus_weak center_focus_weak icon
   * @enum {value} change_history change_history icon
   * @enum {value} chat chat icon
   * @enum {value} chat-bubbles chat-bubbles icon
   * @enum {value} chat_bubble chat_bubble icon
   * @enum {value} chat_bubble_outline chat_bubble_outline icon
   * @enum {value} check check icon
   * @enum {value} check_box check_box icon
   * @enum {value} check_box_outline_blank check_box_outline_blank icon
   * @enum {value} check_circle check_circle icon
   * @enum {value} checkmark checkmark icon
   * @enum {value} checkmark-circle checkmark-circle icon
   * @enum {value} checkmark-circle-fill checkmark-circle-fill icon
   * @enum {value} chevron-down chevron-down icon
   * @enum {value} chevron-left chevron-left icon
   * @enum {value} chevron-right chevron-right icon
   * @enum {value} chevron-right-bold chevron-right-bold icon
   * @enum {value} chevron-up chevron-up icon
   * @enum {value} child_care child_care icon
   * @enum {value} child_friendly child_friendly icon
   * @enum {value} chrome_reader_mode chrome_reader_mode icon
   * @enum {value} class class icon
   * @enum {value} clear clear icon
   * @enum {value} clear_all clear_all icon
   * @enum {value} clock clock icon
   * @enum {value} clock-fill clock-fill icon
   * @enum {value} close close icon
   * @enum {value} closed_caption closed_caption icon
   * @enum {value} cloud cloud icon
   * @enum {value} cloud_circle cloud_circle icon
   * @enum {value} cloud_done cloud_done icon
   * @enum {value} cloud_download cloud_download icon
   * @enum {value} cloud_off cloud_off icon
   * @enum {value} cloud_queue cloud_queue icon
   * @enum {value} cloud_upload cloud_upload icon
   * @enum {value} code code icon
   * @enum {value} collections collections icon
   * @enum {value} collections_bookmark collections_bookmark icon
   * @enum {value} color_lens color_lens icon
   * @enum {value} colorize colorize icon
   * @enum {value} comment comment icon
   * @enum {value} compare compare icon
   * @enum {value} compare_arrows compare_arrows icon
   * @enum {value} computer computer icon
   * @enum {value} confirmation_num confirmation_num icon
   * @enum {value} confirmation_number confirmation_number icon
   * @enum {value} contact_mail contact_mail icon
   * @enum {value} contact_phone contact_phone icon
   * @enum {value} contacts contacts icon
   * @enum {value} content_copy content_copy icon
   * @enum {value} content_cut content_cut icon
   * @enum {value} content_paste content_paste icon
   * @enum {value} control_point control_point icon
   * @enum {value} control_point_duplicate control_point_duplicate icon
   * @enum {value} copyright copyright icon
   * @enum {value} create create icon
   * @enum {value} create_new_folder create_new_folder icon
   * @enum {value} credit_card credit_card icon
   * @enum {value} crop crop icon
   * @enum {value} crop_din crop_din icon
   * @enum {value} crop_five_four crop_five_four icon
   * @enum {value} crop_free crop_free icon
   * @enum {value} crop_landscape crop_landscape icon
   * @enum {value} crop_onesix_nine crop_onesix_nine icon
   * @enum {value} crop_original crop_original icon
   * @enum {value} crop_portrait crop_portrait icon
   * @enum {value} crop_rotate crop_rotate icon
   * @enum {value} crop_seven_five crop_seven_five icon
   * @enum {value} crop_square crop_square icon
   * @enum {value} crop_three_two crop_three_two icon
   * @enum {value} dashboard dashboard icon
   * @enum {value} danger Alias for "alert-circle-fill"
   * @enum {value} data_usage data_usage icon
   * @enum {value} date_range date_range icon
   * @enum {value} dehaze dehaze icon
   * @enum {value} delete delete icon
   * @enum {value} delete_forever delete_forever icon
   * @enum {value} delete_sweep delete_sweep icon
   * @enum {value} description description icon
   * @enum {value} desktop_mac desktop_mac icon
   * @enum {value} desktop_windows desktop_windows icon
   * @enum {value} details details icon
   * @enum {value} developer_board developer_board icon
   * @enum {value} developer_mode developer_mode icon
   * @enum {value} device_hub device_hub icon
   * @enum {value} devices devices icon
   * @enum {value} devices_other devices_other icon
   * @enum {value} dialer_sip dialer_sip icon
   * @enum {value} dialpad dialpad icon
   * @enum {value} directions directions icon
   * @enum {value} directions_bike directions_bike icon
   * @enum {value} directions_boat directions_boat icon
   * @enum {value} directions_bus directions_bus icon
   * @enum {value} directions_car directions_car icon
   * @enum {value} directions_ferry directions_ferry icon
   * @enum {value} directions_railway directions_railway icon
   * @enum {value} directions_run directions_run icon
   * @enum {value} directions_subway directions_subway icon
   * @enum {value} directions_train directions_train icon
   * @enum {value} directions_transit directions_transit icon
   * @enum {value} directions_walk directions_walk icon
   * @enum {value} disc_full disc_full icon
   * @enum {value} dnd_forwardslash dnd_forwardslash icon
   * @enum {value} dns dns icon
   * @enum {value} do_not_disturb do_not_disturb icon
   * @enum {value} do_not_disturb_alt do_not_disturb_alt icon
   * @enum {value} do_not_disturb_off do_not_disturb_off icon
   * @enum {value} do_not_disturb_on do_not_disturb_on icon
   * @enum {value} dock dock icon
   * @enum {value} domain domain icon
   * @enum {value} done done icon
   * @enum {value} done_all done_all icon
   * @enum {value} donut_large donut_large icon
   * @enum {value} donut_small donut_small icon
   * @enum {value} drafts drafts icon
   * @enum {value} drag-and-drop drag-and-drop icon
   * @enum {value} drag_handle drag_handle icon
   * @enum {value} drive_eta drive_eta icon
   * @enum {value} dvr dvr icon
   * @enum {value} edit edit icon
   * @enum {value} edit_location edit_location icon
   * @enum {value} eject eject icon
   * @enum {value} email email icon
   * @enum {value} email_fill email_fill icon
   * @enum {value} emails emails icon
   * @enum {value} enhance_photo_translate enhance_photo_translate icon
   * @enum {value} enhanced_encryption enhanced_encryption icon
   * @enum {value} equalizer equalizer icon
   * @enum {value} error error icon
   * @enum {value} error_outline error_outline icon
   * @enum {value} euro_symbol euro_symbol icon
   * @enum {value} ev_station ev_station icon
   * @enum {value} event event icon
   * @enum {value} event_available event_available icon
   * @enum {value} event_busy event_busy icon
   * @enum {value} event_note event_note icon
   * @enum {value} event_seat event_seat icon
   * @enum {value} exit Alias for "x"
   * @enum {value} exit_to_app exit_to_app icon
   * @enum {value} expand expand icon
   * @enum {value} expand_less expand_less icon
   * @enum {value} expand_more expand_more icon
   * @enum {value} explicit explicit icon
   * @enum {value} explore explore icon
   * @enum {value} exposure exposure icon
   * @enum {value} exposure_minus_one exposure_minus_one icon
   * @enum {value} exposure_minus_two exposure_minus_two icon
   * @enum {value} exposure_neg_one exposure_neg_one icon
   * @enum {value} exposure_neg_two exposure_neg_two icon
   * @enum {value} exposure_plus_one exposure_plus_one icon
   * @enum {value} exposure_plus_two exposure_plus_two icon
   * @enum {value} exposure_zero exposure_zero icon
   * @enum {value} extension extension icon
   * @enum {value} face face icon
   * @enum {value} fast_forward fast_forward icon
   * @enum {value} fast_rewind fast_rewind icon
   * @enum {value} favorite favorite icon
   * @enum {value} favorite_border favorite_border icon
   * @enum {value} favorite_outline favorite_outline icon
   * @enum {value} featured_play_list featured_play_list icon
   * @enum {value} featured_video featured_video icon
   * @enum {value} feedback feedback icon
   * @enum {value} fiber_dvr fiber_dvr icon
   * @enum {value} fiber_manual_record fiber_manual_record icon
   * @enum {value} fiber_new fiber_new icon
   * @enum {value} fiber_pin fiber_pin icon
   * @enum {value} fiber_smart_record fiber_smart_record icon
   * @enum {value} file_download file_download icon
   * @enum {value} file_upload file_upload icon
   * @enum {value} filter filter icon
   * @enum {value} filter_b_and_w filter_b_and_w icon
   * @enum {value} filter_center_focus filter_center_focus icon
   * @enum {value} filter_drama filter_drama icon
   * @enum {value} filter_eight filter_eight icon
   * @enum {value} filter_five filter_five icon
   * @enum {value} filter_four filter_four icon
   * @enum {value} filter_frames filter_frames icon
   * @enum {value} filter_hdr filter_hdr icon
   * @enum {value} filter_list filter_list icon
   * @enum {value} filter_nine filter_nine icon
   * @enum {value} filter_nine_plus filter_nine_plus icon
   * @enum {value} filter_none filter_none icon
   * @enum {value} filter_one filter_one icon
   * @enum {value} filter_seven filter_seven icon
   * @enum {value} filter_six filter_six icon
   * @enum {value} filter_three filter_three icon
   * @enum {value} filter_tilt_shift filter_tilt_shift icon
   * @enum {value} filter_two filter_two icon
   * @enum {value} filter_vintage filter_vintage icon
   * @enum {value} find_in_page find_in_page icon
   * @enum {value} find_replace find_replace icon
   * @enum {value} fingerprint fingerprint icon
   * @enum {value} first_page first_page icon
   * @enum {value} fitness_center fitness_center icon
   * @enum {value} flag flag icon
   * @enum {value} flare flare icon
   * @enum {value} flash_auto flash_auto icon
   * @enum {value} flash_off flash_off icon
   * @enum {value} flash_on flash_on icon
   * @enum {value} flight flight icon
   * @enum {value} flight_land flight_land icon
   * @enum {value} flight_takeoff flight_takeoff icon
   * @enum {value} flip flip icon
   * @enum {value} flip_to_back flip_to_back icon
   * @enum {value} flip_to_front flip_to_front icon
   * @enum {value} folder folder icon
   * @enum {value} folder_open folder_open icon
   * @enum {value} folder_shared folder_shared icon
   * @enum {value} folder_special folder_special icon
   * @enum {value} font_download font_download icon
   * @enum {value} format_align_center format_align_center icon
   * @enum {value} format_align_justify format_align_justify icon
   * @enum {value} format_align_left format_align_left icon
   * @enum {value} format_align_right format_align_right icon
   * @enum {value} format_bold format_bold icon
   * @enum {value} format_clear format_clear icon
   * @enum {value} format_color_fill format_color_fill icon
   * @enum {value} format_color_reset format_color_reset icon
   * @enum {value} format_color_text format_color_text icon
   * @enum {value} format_indent_decrease format_indent_decrease icon
   * @enum {value} format_indent_increase format_indent_increase icon
   * @enum {value} format_italic format_italic icon
   * @enum {value} format_line_spacing format_line_spacing icon
   * @enum {value} format_list_bulleted format_list_bulleted icon
   * @enum {value} format_list_numbered format_list_numbered icon
   * @enum {value} format_paint format_paint icon
   * @enum {value} format_quote format_quote icon
   * @enum {value} format_shapes format_shapes icon
   * @enum {value} format_size format_size icon
   * @enum {value} format_strikethrough format_strikethrough icon
   * @enum {value} format_textdirection_l_to_r format_textdirection_l_to_r icon
   * @enum {value} format_textdirection_r_to_l format_textdirection_r_to_l icon
   * @enum {value} format_underline format_underline icon
   * @enum {value} format_underlined format_underlined icon
   * @enum {value} forum forum icon
   * @enum {value} forward forward icon
   * @enum {value} forward_five forward_five icon
   * @enum {value} forward_onezero forward_onezero icon
   * @enum {value} forward_threezero forward_threezero icon
   * @enum {value} free_breakfast free_breakfast icon
   * @enum {value} fullscreen fullscreen icon
   * @enum {value} fullscreen_exit fullscreen_exit icon
   * @enum {value} functions functions icon
   * @enum {value} g_translate g_translate icon
   * @enum {value} gamepad gamepad icon
   * @enum {value} games games icon
   * @enum {value} gavel gavel icon
   * @enum {value} gear gear icon
   * @enum {value} gesture gesture icon
   * @enum {value} get_app get_app icon
   * @enum {value} gif gif icon
   * @enum {value} globe globe icon
   * @enum {value} golf_course golf_course icon
   * @enum {value} gps_fixed gps_fixed icon
   * @enum {value} gps_not_fixed gps_not_fixed icon
   * @enum {value} gps_off gps_off icon
   * @enum {value} grade grade icon
   * @enum {value} gradient gradient icon
   * @enum {value} grain grain icon
   * @enum {value} graph graph icon
   * @enum {value} graphic_eq graphic_eq icon
   * @enum {value} grid_off grid_off icon
   * @enum {value} grid_on grid_on icon
   * @enum {value} group group icon
   * @enum {value} group_add group_add icon
   * @enum {value} group_work group_work icon
   * @enum {value} hamburger hamburger icon
   * @enum {value} hd hd icon
   * @enum {value} hdr_off hdr_off icon
   * @enum {value} hdr_on hdr_on icon
   * @enum {value} hdr_strong hdr_strong icon
   * @enum {value} hdr_weak hdr_weak icon
   * @enum {value} headset headset icon
   * @enum {value} headset_mic headset_mic icon
   * @enum {value} healing healing icon
   * @enum {value} hearing hearing icon
   * @enum {value} help help icon
   * @enum {value} help_outline help_outline icon
   * @enum {value} high_quality high_quality icon
   * @enum {value} highlight highlight icon
   * @enum {value} highlight_off highlight_off icon
   * @enum {value} highlight_remove highlight_remove icon
   * @enum {value} hint Alias for "help_outline"
   * @enum {value} history history icon
   * @enum {value} home home icon
   * @enum {value} horn horn icon
   * @enum {value} hot_tub hot_tub icon
   * @enum {value} hotel hotel icon
   * @enum {value} hourglass_empty hourglass_empty icon
   * @enum {value} hourglass_full hourglass_full icon
   * @enum {value} http http icon
   * @enum {value} https https icon
   * @enum {value} image image icon
   * @enum {value} image_aspect_ratio image_aspect_ratio icon
   * @enum {value} import_contacts import_contacts icon
   * @enum {value} import_export import_export icon
   * @enum {value} important_devices important_devices icon
   * @enum {value} inbox inbox icon
   * @enum {value} indeterminate_check_box indeterminate_check_box icon
   * @enum {value} info info icon
   * @enum {value} info-circle info-circle icon
   * @enum {value} info-circle-fill info-circle-fill icon
   * @enum {value} info_outline info_outline icon
   * @enum {value} input input icon
   * @enum {value} insert_chart insert_chart icon
   * @enum {value} insert_comment insert_comment icon
   * @enum {value} insert_drive_file insert_drive_file icon
   * @enum {value} insert_emoticon insert_emoticon icon
   * @enum {value} insert_invitation insert_invitation icon
   * @enum {value} insert_link insert_link icon
   * @enum {value} insert_photo insert_photo icon
   * @enum {value} invert_colors invert_colors icon
   * @enum {value} invert_colors_off invert_colors_off icon
   * @enum {value} invert_colors_on invert_colors_on icon
   * @enum {value} iso iso icon
   * @enum {value} keyboard keyboard icon
   * @enum {value} keyboard_arrow_down keyboard_arrow_down icon
   * @enum {value} keyboard_arrow_left keyboard_arrow_left icon
   * @enum {value} keyboard_arrow_right keyboard_arrow_right icon
   * @enum {value} keyboard_arrow_up keyboard_arrow_up icon
   * @enum {value} keyboard_backspace keyboard_backspace icon
   * @enum {value} keyboard_capslock keyboard_capslock icon
   * @enum {value} keyboard_control keyboard_control icon
   * @enum {value} keyboard_hide keyboard_hide icon
   * @enum {value} keyboard_return keyboard_return icon
   * @enum {value} keyboard_tab keyboard_tab icon
   * @enum {value} keyboard_voice keyboard_voice icon
   * @enum {value} kitchen kitchen icon
   * @enum {value} label label icon
   * @enum {value} label_outline label_outline icon
   * @enum {value} landscape landscape icon
   * @enum {value} language language icon
   * @enum {value} laptop laptop icon
   * @enum {value} laptop_chromebook laptop_chromebook icon
   * @enum {value} laptop_mac laptop_mac icon
   * @enum {value} laptop_windows laptop_windows icon
   * @enum {value} last_page last_page icon
   * @enum {value} launch launch icon
   * @enum {value} layers layers icon
   * @enum {value} layers_clear layers_clear icon
   * @enum {value} leak_add leak_add icon
   * @enum {value} leak_remove leak_remove icon
   * @enum {value} lens lens icon
   * @enum {value} library_add library_add icon
   * @enum {value} library_books library_books icon
   * @enum {value} library_music library_music icon
   * @enum {value} lightbulb_outline lightbulb_outline icon
   * @enum {value} lightning lightning icon
   * @enum {value} lightning-fill lightning-fill icon
   * @enum {value} line_style line_style icon
   * @enum {value} line_weight line_weight icon
   * @enum {value} linear_scale linear_scale icon
   * @enum {value} link link icon
   * @enum {value} linked_camera linked_camera icon
   * @enum {value} list list icon
   * @enum {value} live_help live_help icon
   * @enum {value} live_tv live_tv icon
   * @enum {value} local_activity local_activity icon
   * @enum {value} local_airport local_airport icon
   * @enum {value} local_atm local_atm icon
   * @enum {value} local_attraction local_attraction icon
   * @enum {value} local_bar local_bar icon
   * @enum {value} local_cafe local_cafe icon
   * @enum {value} local_car_wash local_car_wash icon
   * @enum {value} local_convenience_store local_convenience_store icon
   * @enum {value} local_dining local_dining icon
   * @enum {value} local_drink local_drink icon
   * @enum {value} local_florist local_florist icon
   * @enum {value} local_gas_station local_gas_station icon
   * @enum {value} local_grocery_store local_grocery_store icon
   * @enum {value} local_hospital local_hospital icon
   * @enum {value} local_hotel local_hotel icon
   * @enum {value} local_laundry_service local_laundry_service icon
   * @enum {value} local_library local_library icon
   * @enum {value} local_mall local_mall icon
   * @enum {value} local_movies local_movies icon
   * @enum {value} local_offer local_offer icon
   * @enum {value} local_parking local_parking icon
   * @enum {value} local_pharmacy local_pharmacy icon
   * @enum {value} local_phone local_phone icon
   * @enum {value} local_pizza local_pizza icon
   * @enum {value} local_play local_play icon
   * @enum {value} local_post_office local_post_office icon
   * @enum {value} local_print_shop local_print_shop icon
   * @enum {value} local_printshop local_printshop icon
   * @enum {value} local_restaurant local_restaurant icon
   * @enum {value} local_see local_see icon
   * @enum {value} local_shipping local_shipping icon
   * @enum {value} local_taxi local_taxi icon
   * @enum {value} location_city location_city icon
   * @enum {value} location_disabled location_disabled icon
   * @enum {value} location_history location_history icon
   * @enum {value} location_off location_off icon
   * @enum {value} location_on location_on icon
   * @enum {value} location_searching location_searching icon
   * @enum {value} lock lock icon
   * @enum {value} lock_open lock_open icon
   * @enum {value} lock_outline lock_outline icon
   * @enum {value} looks looks icon
   * @enum {value} looks_five looks_five icon
   * @enum {value} looks_four looks_four icon
   * @enum {value} looks_one looks_one icon
   * @enum {value} looks_six looks_six icon
   * @enum {value} looks_three looks_three icon
   * @enum {value} looks_two looks_two icon
   * @enum {value} loop loop icon
   * @enum {value} loupe loupe icon
   * @enum {value} low_priority low_priority icon
   * @enum {value} loyalty loyalty icon
   * @enum {value} mail mail icon
   * @enum {value} mail_outline mail_outline icon
   * @enum {value} map map icon
   * @enum {value} markunread markunread icon
   * @enum {value} markunread_mailbox markunread_mailbox icon
   * @enum {value} memory memory icon
   * @enum {value} menu menu icon
   * @enum {value} merge_type merge_type icon
   * @enum {value} message message icon
   * @enum {value} messenger messenger icon
   * @enum {value} messenger_outline messenger_outline icon
   * @enum {value} mic mic icon
   * @enum {value} mic_none mic_none icon
   * @enum {value} mic_off mic_off icon
   * @enum {value} microphone microphone icon
   * @enum {value} minus minus icon
   * @enum {value} minus-circle minus-circle icon
   * @enum {value} minus-circle-fill minus-circle-fill icon
   * @enum {value} mms mms icon
   * @enum {value} mode_comment mode_comment icon
   * @enum {value} mode_edit mode_edit icon
   * @enum {value} monetization_on monetization_on icon
   * @enum {value} money money icon
   * @enum {value} money_off money_off icon
   * @enum {value} monochrome_photos monochrome_photos icon
   * @enum {value} mood mood icon
   * @enum {value} mood_bad mood_bad icon
   * @enum {value} more more icon
   * @enum {value} more_horiz more_horiz icon
   * @enum {value} more_vert more_vert icon
   * @enum {value} motorcycle motorcycle icon
   * @enum {value} mouse mouse icon
   * @enum {value} move_to_inbox move_to_inbox icon
   * @enum {value} movie movie icon
   * @enum {value} movie_creation movie_creation icon
   * @enum {value} movie_filter movie_filter icon
   * @enum {value} multiline_chart multiline_chart icon
   * @enum {value} multitrack_audio multitrack_audio icon
   * @enum {value} music_note music_note icon
   * @enum {value} music_video music_video icon
   * @enum {value} my_library_add my_library_add icon
   * @enum {value} my_library_books my_library_books icon
   * @enum {value} my_library_music my_library_music icon
   * @enum {value} my_location my_location icon
   * @enum {value} nature nature icon
   * @enum {value} nature_people nature_people icon
   * @enum {value} navigate_before navigate_before icon
   * @enum {value} navigate_next navigate_next icon
   * @enum {value} navigation navigation icon
   * @enum {value} near_me near_me icon
   * @enum {value} network_cell network_cell icon
   * @enum {value} network_check network_check icon
   * @enum {value} network_locked network_locked icon
   * @enum {value} network_wifi network_wifi icon
   * @enum {value} new_releases new_releases icon
   * @enum {value} next_week next_week icon
   * @enum {value} nfc nfc icon
   * @enum {value} no-reply no-reply icon
   * @enum {value} no_encryption no_encryption icon
   * @enum {value} no_sim no_sim icon
   * @enum {value} not_interested not_interested icon
   * @enum {value} note note icon
   * @enum {value} note_add note_add icon
   * @enum {value} notifications notifications icon
   * @enum {value} notifications_active notifications_active icon
   * @enum {value} notifications_none notifications_none icon
   * @enum {value} notifications_off notifications_off icon
   * @enum {value} notifications_on notifications_on icon
   * @enum {value} notifications_paused notifications_paused icon
   * @enum {value} now_wallpaper now_wallpaper icon
   * @enum {value} now_widgets now_widgets icon
   * @enum {value} number-eight number-eight icon
   * @enum {value} number-five number-five icon
   * @enum {value} number-four number-four icon
   * @enum {value} number-nine number-nine icon
   * @enum {value} number-one number-one icon
   * @enum {value} number-seven number-seven icon
   * @enum {value} number-six number-six icon
   * @enum {value} number-three number-three icon
   * @enum {value} number-two number-two icon
   * @enum {value} offline_pin offline_pin icon
   * @enum {value} ondemand_video ondemand_video icon
   * @enum {value} opacity opacity icon
   * @enum {value} open_in_browser open_in_browser icon
   * @enum {value} open_in_new open_in_new icon
   * @enum {value} open_with open_with icon
   * @enum {value} pages pages icon
   * @enum {value} pageview pageview icon
   * @enum {value} palette palette icon
   * @enum {value} pan_tool pan_tool icon
   * @enum {value} panorama panorama icon
   * @enum {value} panorama_fish_eye panorama_fish_eye icon
   * @enum {value} panorama_fisheye panorama_fisheye icon
   * @enum {value} panorama_horizontal panorama_horizontal icon
   * @enum {value} panorama_vertical panorama_vertical icon
   * @enum {value} panorama_wide_angle panorama_wide_angle icon
   * @enum {value} paper paper icon
   * @enum {value} paper-airplane paper-airplane icon
   * @enum {value} paperclip paperclip icon
   * @enum {value} party_mode party_mode icon
   * @enum {value} pause pause icon
   * @enum {value} pause_circle_filled pause_circle_filled icon
   * @enum {value} pause_circle_outline pause_circle_outline icon
   * @enum {value} payment payment icon
   * @enum {value} pencil pencil icon
   * @enum {value} pencil-circle pencil-circle icon
   * @enum {value} pencil-circle-fill pencil-circle-fill icon
   * @enum {value} people people icon
   * @enum {value} people_outline people_outline icon
   * @enum {value} perm_camera_mic perm_camera_mic icon
   * @enum {value} perm_contact_cal perm_contact_cal icon
   * @enum {value} perm_contact_calendar perm_contact_calendar icon
   * @enum {value} perm_data_setting perm_data_setting icon
   * @enum {value} perm_device_info perm_device_info icon
   * @enum {value} perm_device_information perm_device_information icon
   * @enum {value} perm_identity perm_identity icon
   * @enum {value} perm_media perm_media icon
   * @enum {value} perm_phone_msg perm_phone_msg icon
   * @enum {value} perm_scan_wifi perm_scan_wifi icon
   * @enum {value} person person icon
   * @enum {value} person_add person_add icon
   * @enum {value} person_outline person_outline icon
   * @enum {value} person_pin person_pin icon
   * @enum {value} person_pin_circle person_pin_circle icon
   * @enum {value} personal_video personal_video icon
   * @enum {value} pets pets icon
   * @enum {value} phone phone icon
   * @enum {value} phone_android phone_android icon
   * @enum {value} phone_bluetooth_speaker phone_bluetooth_speaker icon
   * @enum {value} phone_forwarded phone_forwarded icon
   * @enum {value} phone_in_talk phone_in_talk icon
   * @enum {value} phone_iphone phone_iphone icon
   * @enum {value} phone_locked phone_locked icon
   * @enum {value} phone_missed phone_missed icon
   * @enum {value} phone_paused phone_paused icon
   * @enum {value} phonelink phonelink icon
   * @enum {value} phonelink_erase phonelink_erase icon
   * @enum {value} phonelink_lock phonelink_lock icon
   * @enum {value} phonelink_off phonelink_off icon
   * @enum {value} phonelink_ring phonelink_ring icon
   * @enum {value} phonelink_setup phonelink_setup icon
   * @enum {value} photo photo icon
   * @enum {value} photo_album photo_album icon
   * @enum {value} photo_camera photo_camera icon
   * @enum {value} photo_filter photo_filter icon
   * @enum {value} photo_library photo_library icon
   * @enum {value} photo_size_select_actual photo_size_select_actual icon
   * @enum {value} photo_size_select_large photo_size_select_large icon
   * @enum {value} photo_size_select_small photo_size_select_small icon
   * @enum {value} picture picture icon
   * @enum {value} picture_as_pdf picture_as_pdf icon
   * @enum {value} picture_in_picture picture_in_picture icon
   * @enum {value} picture_in_picture_alt picture_in_picture_alt icon
   * @enum {value} pie_chart pie_chart icon
   * @enum {value} pie_chart_outlined pie_chart_outlined icon
   * @enum {value} pin_drop pin_drop icon
   * @enum {value} place place icon
   * @enum {value} play play icon
   * @enum {value} play-fill play-fill icon
   * @enum {value} play_arrow play_arrow icon
   * @enum {value} play_circle_fill play_circle_fill icon
   * @enum {value} play_circle_filled play_circle_filled icon
   * @enum {value} play_circle_outline play_circle_outline icon
   * @enum {value} play_for_work play_for_work icon
   * @enum {value} playlist_add playlist_add icon
   * @enum {value} playlist_add_check playlist_add_check icon
   * @enum {value} playlist_play playlist_play icon
   * @enum {value} plus plus icon
   * @enum {value} plus-circle plus-circle icon
   * @enum {value} plus-circle-fill plus-circle-fill icon
   * @enum {value} plus_one plus_one icon
   * @enum {value} poll poll icon
   * @enum {value} polymer polymer icon
   * @enum {value} pool pool icon
   * @enum {value} portable_wifi_off portable_wifi_off icon
   * @enum {value} portrait portrait icon
   * @enum {value} power power icon
   * @enum {value} power_input power_input icon
   * @enum {value} power_settings_new power_settings_new icon
   * @enum {value} pregnant_woman pregnant_woman icon
   * @enum {value} present_to_all present_to_all icon
   * @enum {value} price-tag price-tag icon
   * @enum {value} price-tag-fill price-tag-fill icon
   * @enum {value} price-tag-plus price-tag-plus icon
   * @enum {value} print print icon
   * @enum {value} print-default print-default icon
   * @enum {value} print-left print-left icon
   * @enum {value} print-right print-right icon
   * @enum {value} print-with-receipt print-with-receipt icon
   * @enum {value} priority_high priority_high icon
   * @enum {value} public public icon
   * @enum {value} publish publish icon
   * @enum {value} query_builder query_builder icon
   * @enum {value} question_answer question_answer icon
   * @enum {value} queue queue icon
   * @enum {value} queue_music queue_music icon
   * @enum {value} queue_play_next queue_play_next icon
   * @enum {value} quick_contacts_dialer quick_contacts_dialer icon
   * @enum {value} quick_contacts_mail quick_contacts_mail icon
   * @enum {value} radio radio icon
   * @enum {value} radio_button_checked radio_button_checked icon
   * @enum {value} radio_button_off radio_button_off icon
   * @enum {value} radio_button_on radio_button_on icon
   * @enum {value} radio_button_unchecked radio_button_unchecked icon
   * @enum {value} rate_review rate_review icon
   * @enum {value} receipt receipt icon
   * @enum {value} recent_actors recent_actors icon
   * @enum {value} record_voice_over record_voice_over icon
   * @enum {value} redeem redeem icon
   * @enum {value} redo redo icon
   * @enum {value} refresh refresh icon
   * @enum {value} remove remove icon
   * @enum {value} remove_circle remove_circle icon
   * @enum {value} remove_circle_outline remove_circle_outline icon
   * @enum {value} remove_from_queue remove_from_queue icon
   * @enum {value} remove_red_eye remove_red_eye icon
   * @enum {value} remove_shopping_cart remove_shopping_cart icon
   * @enum {value} reorder reorder icon
   * @enum {value} repeat repeat icon
   * @enum {value} repeat_one repeat_one icon
   * @enum {value} replay replay icon
   * @enum {value} replay_five replay_five icon
   * @enum {value} replay_onezero replay_onezero icon
   * @enum {value} replay_threezero replay_threezero icon
   * @enum {value} reply reply icon
   * @enum {value} reply_all reply_all icon
   * @enum {value} report report icon
   * @enum {value} report_problem report_problem icon
   * @enum {value} restaurant restaurant icon
   * @enum {value} restaurant_menu restaurant_menu icon
   * @enum {value} restore restore icon
   * @enum {value} restore_page restore_page icon
   * @enum {value} return return icon
   * @enum {value} ring_volume ring_volume icon
   * @enum {value} room room icon
   * @enum {value} room_service room_service icon
   * @enum {value} rotate_left rotate_left icon
   * @enum {value} rotate_ninezero_degrees_ccw rotate_ninezero_degrees_ccw icon
   * @enum {value} rotate_right rotate_right icon
   * @enum {value} rounded_corner rounded_corner icon
   * @enum {value} router router icon
   * @enum {value} rowing rowing icon
   * @enum {value} rss_feed rss_feed icon
   * @enum {value} rv_hookup rv_hookup icon
   * @enum {value} satellite satellite icon
   * @enum {value} save save icon
   * @enum {value} scanner scanner icon
   * @enum {value} schedule schedule icon
   * @enum {value} school school icon
   * @enum {value} screen_lock_landscape screen_lock_landscape icon
   * @enum {value} screen_lock_portrait screen_lock_portrait icon
   * @enum {value} screen_lock_rotation screen_lock_rotation icon
   * @enum {value} screen_rotation screen_rotation icon
   * @enum {value} screen_share screen_share icon
   * @enum {value} sd_card sd_card icon
   * @enum {value} sd_storage sd_storage icon
   * @enum {value} search search icon
   * @enum {value} search-amazon search-amazon icon
   * @enum {value} security security icon
   * @enum {value} select_all select_all icon
   * @enum {value} send send icon
   * @enum {value} sentiment_dissatisfied sentiment_dissatisfied icon
   * @enum {value} sentiment_neutral sentiment_neutral icon
   * @enum {value} sentiment_satisfied sentiment_satisfied icon
   * @enum {value} sentiment_very_dissatisfied sentiment_very_dissatisfied icon
   * @enum {value} sentiment_very_satisfied sentiment_very_satisfied icon
   * @enum {value} settings settings icon
   * @enum {value} settings_applications settings_applications icon
   * @enum {value} settings_backup_restore settings_backup_restore icon
   * @enum {value} settings_bluetooth settings_bluetooth icon
   * @enum {value} settings_brightness settings_brightness icon
   * @enum {value} settings_cell settings_cell icon
   * @enum {value} settings_display settings_display icon
   * @enum {value} settings_ethernet settings_ethernet icon
   * @enum {value} settings_input_antenna settings_input_antenna icon
   * @enum {value} settings_input_component settings_input_component icon
   * @enum {value} settings_input_composite settings_input_composite icon
   * @enum {value} settings_input_hdmi settings_input_hdmi icon
   * @enum {value} settings_input_svideo settings_input_svideo icon
   * @enum {value} settings_overscan settings_overscan icon
   * @enum {value} settings_phone settings_phone icon
   * @enum {value} settings_power settings_power icon
   * @enum {value} settings_remote settings_remote icon
   * @enum {value} settings_system_daydream settings_system_daydream icon
   * @enum {value} settings_voice settings_voice icon
   * @enum {value} share share icon
   * @enum {value} shipment shipment icon
   * @enum {value} shop shop icon
   * @enum {value} shop_two shop_two icon
   * @enum {value} shopping_basket shopping_basket icon
   * @enum {value} shopping_cart shopping_cart icon
   * @enum {value} short_text short_text icon
   * @enum {value} show_chart show_chart icon
   * @enum {value} shuffle shuffle icon
   * @enum {value} signal_cellular_connected_no_internet_four_bar signal_cellular_connected_no_internet_four_bar icon
   * @enum {value} signal_cellular_four_bar signal_cellular_four_bar icon
   * @enum {value} signal_cellular_no_sim signal_cellular_no_sim icon
   * @enum {value} signal_cellular_null signal_cellular_null icon
   * @enum {value} signal_cellular_off signal_cellular_off icon
   * @enum {value} signal_wifi_four_bar signal_wifi_four_bar icon
   * @enum {value} signal_wifi_four_bar_lock signal_wifi_four_bar_lock icon
   * @enum {value} signal_wifi_off signal_wifi_off icon
   * @enum {value} sim_card sim_card icon
   * @enum {value} sim_card_alert sim_card_alert icon
   * @enum {value} skip_next skip_next icon
   * @enum {value} skip_previous skip_previous icon
   * @enum {value} slideshow slideshow icon
   * @enum {value} slow_motion_video slow_motion_video icon
   * @enum {value} smartphone smartphone icon
   * @enum {value} smoke_free smoke_free icon
   * @enum {value} smoking_rooms smoking_rooms icon
   * @enum {value} sms sms icon
   * @enum {value} sms_failed sms_failed icon
   * @enum {value} snooze snooze icon
   * @enum {value} sort sort icon
   * @enum {value} sort-alt sort-alt icon
   * @enum {value} sort_alt2 sort_alt2 icon
   * @enum {value} sort_by_alpha sort_by_alpha icon
   * @enum {value} spa spa icon
   * @enum {value} space_bar space_bar icon
   * @enum {value} sparkle sparkle icon
   * @enum {value} speaker speaker icon
   * @enum {value} speaker_group speaker_group icon
   * @enum {value} speaker_notes speaker_notes icon
   * @enum {value} speaker_notes_off speaker_notes_off icon
   * @enum {value} speaker_phone speaker_phone icon
   * @enum {value} spellcheck spellcheck icon
   * @enum {value} spinner spinner icon
   * @enum {value} star star icon
   * @enum {value} star-fill star-fill icon
   * @enum {value} star-half star-half icon
   * @enum {value} star_border star_border icon
   * @enum {value} star_outline star_outline icon
   * @enum {value} stars stars icon
   * @enum {value} stay_current_landscape stay_current_landscape icon
   * @enum {value} stay_current_portrait stay_current_portrait icon
   * @enum {value} stay_primary_landscape stay_primary_landscape icon
   * @enum {value} stay_primary_portrait stay_primary_portrait icon
   * @enum {value} stop stop icon
   * @enum {value} stop_screen_share stop_screen_share icon
   * @enum {value} storage storage icon
   * @enum {value} store store icon
   * @enum {value} store_mall_directory store_mall_directory icon
   * @enum {value} straighten straighten icon
   * @enum {value} streetview streetview icon
   * @enum {value} strikethrough_s strikethrough_s icon
   * @enum {value} style style icon
   * @enum {value} subdirectory_arrow_left subdirectory_arrow_left icon
   * @enum {value} subdirectory_arrow_right subdirectory_arrow_right icon
   * @enum {value} subject subject icon
   * @enum {value} subscriptions subscriptions icon
   * @enum {value} subtitles subtitles icon
   * @enum {value} subway subway icon
   * @enum {value} success Alias for "checkmark"
   * @enum {value} supervisor_account supervisor_account icon
   * @enum {value} surround_sound surround_sound icon
   * @enum {value} swap_calls swap_calls icon
   * @enum {value} swap_horiz swap_horiz icon
   * @enum {value} swap_vert swap_vert icon
   * @enum {value} swap_vert_circle swap_vert_circle icon
   * @enum {value} swap_vertical_circle swap_vertical_circle icon
   * @enum {value} switch_camera switch_camera icon
   * @enum {value} switch_video switch_video icon
   * @enum {value} sync sync icon
   * @enum {value} sync_disabled sync_disabled icon
   * @enum {value} sync_problem sync_problem icon
   * @enum {value} system_update system_update icon
   * @enum {value} system_update_alt system_update_alt icon
   * @enum {value} system_update_tv system_update_tv icon
   * @enum {value} tab tab icon
   * @enum {value} tab_unselected tab_unselected icon
   * @enum {value} tablet tablet icon
   * @enum {value} tablet_android tablet_android icon
   * @enum {value} tablet_mac tablet_mac icon
   * @enum {value} tag_faces tag_faces icon
   * @enum {value} tap_and_play tap_and_play icon
   * @enum {value} terrain terrain icon
   * @enum {value} text_fields text_fields icon
   * @enum {value} text_format text_format icon
   * @enum {value} textsms textsms icon
   * @enum {value} texture texture icon
   * @enum {value} theaters theaters icon
   * @enum {value} threed_rotation threed_rotation icon
   * @enum {value} thumb_down thumb_down icon
   * @enum {value} thumb_up thumb_up icon
   * @enum {value} thumbs_up_down thumbs_up_down icon
   * @enum {value} time_to_leave time_to_leave icon
   * @enum {value} timelapse timelapse icon
   * @enum {value} timeline timeline icon
   * @enum {value} timer timer icon
   * @enum {value} timer_off timer_off icon
   * @enum {value} timer_onezero timer_onezero icon
   * @enum {value} timer_three timer_three icon
   * @enum {value} title title icon
   * @enum {value} toc toc icon
   * @enum {value} today today icon
   * @enum {value} toll toll icon
   * @enum {value} tonality tonality icon
   * @enum {value} touch_app touch_app icon
   * @enum {value} toys toys icon
   * @enum {value} track_changes track_changes icon
   * @enum {value} traffic traffic icon
   * @enum {value} train train icon
   * @enum {value} tram tram icon
   * @enum {value} transfer_within_a_station transfer_within_a_station icon
   * @enum {value} transform transform icon
   * @enum {value} translate translate icon
   * @enum {value} trash trash icon
   * @enum {value} trending_down trending_down icon
   * @enum {value} trending_flat trending_flat icon
   * @enum {value} trending_neutral trending_neutral icon
   * @enum {value} trending_up trending_up icon
   * @enum {value} truck truck icon
   * @enum {value} tune tune icon
   * @enum {value} turned_in turned_in icon
   * @enum {value} turned_in_not turned_in_not icon
   * @enum {value} tv tv icon
   * @enum {value} unarchive unarchive icon
   * @enum {value} undo undo icon
   * @enum {value} unfold_less unfold_less icon
   * @enum {value} unfold_more unfold_more icon
   * @enum {value} unsorted unsorted icon
   * @enum {value} update update icon
   * @enum {value} usb usb icon
   * @enum {value} verified_user verified_user icon
   * @enum {value} vertical-dots vertical-dots icon
   * @enum {value} vertical_align_bottom vertical_align_bottom icon
   * @enum {value} vertical_align_center vertical_align_center icon
   * @enum {value} vertical_align_top vertical_align_top icon
   * @enum {value} vibration vibration icon
   * @enum {value} video_call video_call icon
   * @enum {value} video_collection video_collection icon
   * @enum {value} video_label video_label icon
   * @enum {value} video_library video_library icon
   * @enum {value} videocam videocam icon
   * @enum {value} videocam_off videocam_off icon
   * @enum {value} videogame_asset videogame_asset icon
   * @enum {value} view_agenda view_agenda icon
   * @enum {value} view_array view_array icon
   * @enum {value} view_carousel view_carousel icon
   * @enum {value} view_column view_column icon
   * @enum {value} view_comfortable view_comfortable icon
   * @enum {value} view_comfy view_comfy icon
   * @enum {value} view_compact view_compact icon
   * @enum {value} view_day view_day icon
   * @enum {value} view_headline view_headline icon
   * @enum {value} view_list view_list icon
   * @enum {value} view_module view_module icon
   * @enum {value} view_quilt view_quilt icon
   * @enum {value} view_stream view_stream icon
   * @enum {value} view_week view_week icon
   * @enum {value} vignette vignette icon
   * @enum {value} visibility visibility icon
   * @enum {value} visibility_off visibility_off icon
   * @enum {value} voice_chat voice_chat icon
   * @enum {value} voicemail voicemail icon
   * @enum {value} volume_down volume_down icon
   * @enum {value} volume_mute volume_mute icon
   * @enum {value} volume_off volume_off icon
   * @enum {value} volume_up volume_up icon
   * @enum {value} vpn_key vpn_key icon
   * @enum {value} vpn_lock vpn_lock icon
   * @enum {value} wallet_giftcard wallet_giftcard icon
   * @enum {value} wallet_membership wallet_membership icon
   * @enum {value} wallet_travel wallet_travel icon
   * @enum {value} wallpaper wallpaper icon
   * @enum {value} warning warning icon
   * @enum {value} watch watch icon
   * @enum {value} watch_later watch_later icon
   * @enum {value} wb_auto wb_auto icon
   * @enum {value} wb_cloudy wb_cloudy icon
   * @enum {value} wb_incandescent wb_incandescent icon
   * @enum {value} wb_iridescent wb_iridescent icon
   * @enum {value} wb_sunny wb_sunny icon
   * @enum {value} wc wc icon
   * @enum {value} web web icon
   * @enum {value} web_asset web_asset icon
   * @enum {value} weekend weekend icon
   * @enum {value} whatshot whatshot icon
   * @enum {value} widgets widgets icon
   * @enum {value} wifi wifi icon
   * @enum {value} wifi_lock wifi_lock icon
   * @enum {value} wifi_tethering wifi_tethering icon
   * @enum {value} work work icon
   * @enum {value} wrap_text wrap_text icon
   * @enum {value} x x icon
   * @enum {value} x-circle x-circle icon
   * @enum {value} x-circle-fill x-circle-fill icon
   * @enum {value} youtube_searched_for youtube_searched_for icon
   * @enum {value} zoom_in zoom_in icon
   * @enum {value} zoom_out zoom_out icon
   * @enum {value} zoom_out_map zoom_out_map icon
   *
   * @enum {value} flag-ae UAE flag icon
   * @enum {value} flag-al Albania flag icon
   * @enum {value} flag-am Armenia flag icon
   * @enum {value} flag-at Austria flag icon
   * @enum {value} flag-au Australia flag icon
   * @enum {value} flag-ba Bosnia and Herzegovina flag icon
   * @enum {value} flag-be Belgium flag icon
   * @enum {value} flag-bg Bulgaria flag icon
   * @enum {value} flag-bh Bahrain flag icon
   * @enum {value} flag-br Brazil flag icon
   * @enum {value} flag-by Belarus flag icon
   * @enum {value} flag-ca Canada flag icon
   * @enum {value} flag-ch Switzerland flag icon
   * @enum {value} flag-cl Chile flag icon
   * @enum {value} flag-cn China flag icon
   * @enum {value} flag-co Colombia flag icon
   * @enum {value} flag-cy Cyprus flag icon
   * @enum {value} flag-cz Czech Republic flag icon
   * @enum {value} flag-de Germany flag icon
   * @enum {value} flag-dk Denmark flag icon
   * @enum {value} flag-do Dominican Republic flag icon
   * @enum {value} flag-ee Estonia flag icon
   * @enum {value} flag-eg Egypt flag icon
   * @enum {value} flag-es Spain flag icon
   * @enum {value} flag-eu European Union flag icon
   * @enum {value} flag-fi Finland flag icon
   * @enum {value} flag-fr France flag icon
   * @enum {value} flag-gb Great Britain flag icon
   * @enum {value} flag-ge Georgia flag icon
   * @enum {value} flag-gr Greece flag icon
   * @enum {value} flag-hk Hong Kong flag icon
   * @enum {value} flag-hr Croatia flag icon
   * @enum {value} flag-hu Hungary flag icon
   * @enum {value} flag-ie Ireland flag icon
   * @enum {value} flag-il Israel flag icon
   * @enum {value} flag-in India flag icon
   * @enum {value} flag-is Iceland flag icon
   * @enum {value} flag-it Italy flag icon
   * @enum {value} flag-jo Jordan flag icon
   * @enum {value} flag-jp Japan flag icon
   * @enum {value} flag-kp North Korea flag icon
   * @enum {value} flag-kr South Korea flag icon
   * @enum {value} flag-kw Kuwait flag icon
   * @enum {value} flag-lb Lebanon flag icon
   * @enum {value} flag-li Liechtenstein flag icon
   * @enum {value} flag-lt Lithuania flag icon
   * @enum {value} flag-lu Luxembourg flag icon
   * @enum {value} flag-lv Latvia flag icon
   * @enum {value} flag-ma Morocco flag icon
   * @enum {value} flag-md Moldova flag icon
   * @enum {value} flag-me Montenegro flag icon
   * @enum {value} flag-mk Macedonia flag icon
   * @enum {value} flag-mx Mexico flag icon
   * @enum {value} flag-my Malaysia flag icon
   * @enum {value} flag-mt Malta flag icon
   * @enum {value} flag-ng Nigeria flag icon
   * @enum {value} flag-nl Netherlands flag icon
   * @enum {value} flag-no Norway flag icon
   * @enum {value} flag-nz New Zealand flag icon
   * @enum {value} flag-om Oman flag icon
   * @enum {value} flag-ph Philippines flag icon
   * @enum {value} flag-pl Poland flag icon
   * @enum {value} flag-pt Portugal flag icon
   * @enum {value} flag-qa Qatar flag icon
   * @enum {value} flag-ro Romania flag icon
   * @enum {value} flag-rs Serbia flag icon
   * @enum {value} flag-ru Russia flag icon
   * @enum {value} flag-sa Saudi Arabia flag icon
   * @enum {value} flag-se Sweden flag icon
   * @enum {value} flag-sg Singapore flag icon
   * @enum {value} flag-si Slovenia flag icon
   * @enum {value} flag-sk Slovakia flag icon
   * @enum {value} flag-tr Turkey flag icon
   * @enum {value} flag-ua Ukraine flag icon
   * @enum {value} flag-uk United Kingdom flag icon
   * @enum {value} flag-us United States flag icon
   * @enum {value} flag-xk Kosovo flag icon
   * @enum {value} flag-za South Africa flag icon
   */
  @property()
  name: string;

  /**
   * Size of the icon
   * @enum {value} xxsmall xxsmall
   * @enum {value} xsmall xsmall
   * @enum {value} tiny tiny
   * @enum {value} small small
   * @enum {value} medium medium
   * @enum {value} large large (default)
   * @enum {value} xlarge xlarge
   * @enum {value} xxlarge xxlarge
   */
  @property()
  size?: string;

  static get styles() {
    return [baseStyles, styles];
  }

  connectedCallback() {
    super.connectedCallback();
    onHtmlDirChange(this._rerender);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    offHtmlDirChange(this._rerender);
  }

  private _rerender = () => {
    this.requestUpdate();
  };

  /** Safari requires the `height` property on SVGs */
  private _getSizeSelf = () => {
    const computedStyle = getComputedStyle(this);
    const height =
      computedStyle.getPropertyValue('--kat-icon-size') ||
      computedStyle.getPropertyValue('--kat-icon-size-large');

    // Chromium-base browsers work without height attribute, but fail if added without value
    if (height === '') {
      return null;
    }

    return height;
  };

  render() {
    const name = this.name;
    const isRTL = isPageDirectionRightToLeft();

    if (!name) {
      return html``;
    }

    if (name.indexOf('flag-') !== -1) {
      const flagUrl = getFlagUrl(name);
      if (!flagUrl) {
        return html``;
      }
      return html`<img src="${flagUrl}" aria-hidden="true" />`;
    }

    if (customSvgIcons[name]) {
      return html`
        <svg
          height=${ifNotNull(this._getSizeSelf())}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          ${customSvgIcons[name]}
        </svg>
      `;
    }

    let codePoint = NAME_TO_CODE_POINT[name];
    if (!codePoint) {
      return html``;
    }

    let flipped = false;

    if (isRTL) {
      const rtlCodePoint = NAME_TO_CODE_POINT_FOR_RTL[name];

      /* istanbul ignore else - the original codepoint will be used */
      if (rtlCodePoint) {
        // Change the code point of the selected icon in the
        // RTL mode for the directional icon.
        codePoint = rtlCodePoint;
      } else if (iconsToFlipHorizantly.includes(name)) {
        // Flip the icon horizontally in the RTL.
        flipped = true;
      }
    }

    let codePointAsString = stringsFromCodePoints[codePoint];

    if (!codePointAsString) {
      codePointAsString = String.fromCodePoint(codePoint);
      stringsFromCodePoints[codePoint] = codePointAsString;
    }

    return html`<i ?flipped=${flipped} aria-hidden="true"
      >${codePointAsString}</i
    >`;
  }
}
