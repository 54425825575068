import React from "react";
import { Text } from "@amzn/stencil-react-components/dist/submodules/text";

// Due to Stencil limitation, we can't override the font size of Stencil Heading component
// Create a custom heading component which address both accessibility need and UX font size requirement
// Find more details: https://issues.amazon.com/issues/BA-11847
export const CustomHeading = ({
  children,
  ariaLevel,
  fontSize,
  ...props
}: any) => {

  return <Text
    color='neutral90'
    fontWeight='bold'
    margin={0}
    role="heading"
    aria-level={ariaLevel}
    fontSize={fontSize}
    {...props}
  >
    {children}
  </Text>
}
