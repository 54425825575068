import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col } from "@amzn/stencil-react-components/layout";
import { H1, H2, Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { useTranslation } from "react-i18next";
import { Camera, CameraMode, OutputImageFormat } from "@amzn/hvh-camera-ui";
import jsQR from "jsqr";
import "./ScanQR.scss";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/dist/submodules/button";
import { getQR } from "src/utils/qr-helper";
import { ConnectWidgetData } from "../types";
import { Card } from "@amzn/stencil-react-components/card";
import { ModalBody, ModalContainer, ModalFooter, ModalHeader, WithModal } from "@amzn/stencil-react-components/modal";

interface ScanQRProps {
  onStartCall: (x: ConnectWidgetData | null) => void;
}

type QRCodeContent = ConnectWidgetData | null;

export const ScanQR = ({ onStartCall }: ScanQRProps) => {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState<ConnectWidgetData | null>(null);

  const handleStartCall = () => onStartCall(qrCode);

  return (
    <WithModal
      renderModal={({ close }) => (
        <ModalContainer>
          <ModalHeader>
            <H2>{qrCode ? t("RVP-ScanQR-Code-scan-success") : t("RVP-ScanQR-Code-scan-failure")}</H2>
          </ModalHeader>
          <ModalBody>
            {qrCode ? (
              <Text>{t("RVP-Landing-Description")}</Text>
            ) : (
              <Text>
                <ol>
                  <li>
                    {t("RVP-ScanQR-Code-scan-failure-content")}{" "}
                    <span style={{ whiteSpace: "nowrap" }}>{window.location.origin}</span>
                  </li>
                  <li>{t("RVP-Landing-Instruction-2")}</li>
                  <li>{t("RVP-Landing-Instruction-3")}</li>
                  <li>{t("RVP-Landing-Instruction-4")}</li>
                </ol>
              </Text>
            )}
          </ModalBody>
          {qrCode ? (
            <ModalFooter>
              <Button
                onClick={() => {
                  setQrCode(null);
                  close();
                }}
                data-testid="test-cancel-button"
                variant={ButtonVariant.Secondary}
              >
                {t("RVP-ScanQR-Code-Cancel-Button")}
              </Button>
              <Button onClick={handleStartCall} data-testid="test-join-button" variant={ButtonVariant.Primary}>
                {t("RVP-ScanQR-Code-StartCall-Button")}
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button
                onClick={() => {
                  setQrCode(null);
                  close();
                }}
                data-testid="test-cancel-button"
                variant={ButtonVariant.Primary}
              >
                {t("RVP-ScanQR-Code-Retry-Button")}
              </Button>
            </ModalFooter>
          )}
        </ModalContainer>
      )}
      shouldCloseOnClickOutside={false}
    >
      {({ open }) => (
        <ScanQRContent
          qrCode={qrCode}
          onScanTrigger={(code) => {
            setQrCode(code);
            open();
          }}
        />
      )}
    </WithModal>
  );
};

const ScanQRContent = ({
  onScanTrigger,
  qrCode,
}: {
  onScanTrigger: (x: QRCodeContent) => void;
  qrCode: QRCodeContent;
}) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const { t } = useTranslation();
  const [cameraReady, setCameraReady] = useState(false);

  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
  }, []);

  const onShot = useCallback(
    (imageData: ImageData) => {
      let code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });

      if (!code || !code.data) {
        return;
      }

      onScanTrigger(getQR(code.data));
    },
    [onScanTrigger]
  );

  const onCameraReady = useCallback(() => {
    setCameraReady(true);
  }, []);

  return (
    <Col gridGap="S300" display="flex" width={"100%"}>
      <H1 id="page-title" ref={titleRef} tabIndex={-1} style={{ fontSize: 24, lineHeight: 1.5 }}>
        {t("RVP-ScanQR-Code-Title")}
      </H1>
      <div className="camera-wrapper">
        <Camera
          mode={CameraMode.Auto}
          onShot={onShot}
          autoFramesInterval={250}
          onReady={onCameraReady}
          readonly={qrCode !== null}
          facingMode="user"
          outputFormat={OutputImageFormat.ImageData}
          startButtonText={t("RVP-ScanQR-Code-Scan")}
        />
        {cameraReady && (
          <div data-testid="camera-markers" className="camera-markers">
            <div className="marker lt" />
            <div className="marker rt" />
            <div className="marker lb" />
            <div className="marker rb" />
          </div>
        )}
      </div>

      <Card>
        <Text>{t("RVP-ScanQR-Code-Description")}</Text>
      </Card>
    </Col>
  );
};
