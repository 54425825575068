import React, { useCallback, useEffect, useState } from "react";
import { APP_STATE } from "src/components/types";
import AppLoader from "src/components/Layout/AppLoader";
import { GetPermission } from "src/components/PreCheckDevice/GetPermission";
import { PreCheckDevice } from "./PreCheckDevice";
import ConnectLanding from "src/components/ConnectLanding";
import CallEnded from "src/components/VNHE/CallEnded";
import { AppointmentError } from "./AppointmentError";
import { useTranslation } from "react-i18next";
import { useWidgetData } from "src/hooks/useWidgetData";
interface RootProps {
  rvp?: boolean;
}
const Root = ({ rvp }: RootProps) => {
  const [appState, setAppState] = useState<APP_STATE>(APP_STATE.INIT);

  const { t } = useTranslation();

  const { isFetching, widgetData, error, isCallEnded, nonWidgetErr } = useWidgetData(rvp);

  useEffect(() => {
    if (error || nonWidgetErr) setAppState(APP_STATE.ERROR);
    else if (widgetData && rvp) setAppState(APP_STATE.START_RVP);
    else if (widgetData && !rvp) setAppState(APP_STATE.GET_PERMISSION);
  }, [error, nonWidgetErr, widgetData]);

  const renderContent = useCallback(() => {
    switch (appState) {
      case APP_STATE.GET_PERMISSION:
        document.title = t("VHNE-Document-Title-1");
        return <GetPermission onContinue={() => setAppState(APP_STATE.WAITING_ROOM)} />;
      case APP_STATE.WAITING_ROOM:
        document.title = t("VHNE-Document-Title-2");
        return <PreCheckDevice onContinue={() => setAppState(APP_STATE.START_VNHE)} connectWidgetData={widgetData!} />;
      case APP_STATE.START_VNHE:
      case APP_STATE.START_RVP:
        document.title = t("VHNE-Document-Title-3");
        return <ConnectLanding connectWidgetData={widgetData!} />;
      default:
        return <AppointmentError error={error!} />;
    }
  }, [appState, widgetData]);

  if (isCallEnded) {
    document.title = t("VHNE-Document-Title-4");

    return <CallEnded rvp={rvp} />;
  }

  if (isFetching) {
    document.title = t("VHNE-Document-Title-5");

    return <AppLoader />;
  }

  return renderContent();
};

export default Root;
