import moment from "moment-timezone";
import { getLocale } from "src/config/locale-config";

export function forceDisplayTimezone(timestamp: string, timezone: string): string {
  const realTimestamp = moment.tz(timestamp, timezone);
  const stringWithoutTimezone = realTimestamp.format("YYYY-MM-DD hh:mm:ss A");
  const tmpMoment = moment(stringWithoutTimezone, "YYYY-MM-DD hh:mm:ss A");

  return new Intl.DateTimeFormat(getLocale(), { dateStyle: "medium" }).format(tmpMoment.toDate());
}
