import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { View } from "@amzn/stencil-react-components/dist/submodules/layout";
import { useCheckViewport } from "src/hooks/useCheckViewport";
import { H2, P, Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { Link } from "react-router-dom";
import { getLocale } from "src/config/locale-config";
import { addEventMetric, MetricsWindow } from "src/dependencies/adobe-analytic";
import { useParams } from "react-router-dom";

const eventPageLoad = "page load of 'You do not have a virtual pre-hire appointment scheduled at this time' page";
const eventContactSupport = "click of 'contact support' on no appointment scheduled at this time page";

export const NoAppointment = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { t } = useTranslation();
  const { isSmallViewport } = useCheckViewport();
  const titleRef = useRef<HTMLHeadingElement>(null);

  const locale = getLocale();

  useEffect(() => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventPageLoad);
    if (titleRef.current) titleRef.current.focus();
  }, []);

  const handleContactSupport = () => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventContactSupport);
  };

  const supportAddress = `https://${window.location.hostname}/contact-us`;

  return (
    <View style={{ maxWidth: 572, height: "100%", margin: isSmallViewport ? 0 : "auto" }} data-testid="no-appointment">
      <H2 id="page-title" ref={titleRef} tabIndex={-1} style={{ marginBottom: 20, fontSize: 24, lineHeight: 1.5 }}>
        {t("VNHE-NoAppointment-Title")}
      </H2>
      <P>
        <Text>
          {t("VNHE-NoAppointment-Body")}<Link
            to={supportAddress}
            onClick={handleContactSupport}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="no-appointment-link"
            style={{ textDecoration: "underline" }}
          >
            {t("VNHE-NoAppointment-SupportHelp-Link")}
          </Link>
        </Text>
      </P>
    </View>
  );
};
