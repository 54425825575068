export const initializeCommunicationToAmazonConnect = (windowId, snippetId) => {
  (function (w, d, x, id) {
    var s = d.createElement("script");
    // This url depends on the connect instance!
    s.src = "https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js";
    s.async = 1;
    s.id = id;
    d.getElementsByTagName("head")[0].appendChild(s);
    w[x] =
      w[x] ||
      function () {
        (w[x].ac = w[x].ac || []).push(arguments);
      };
  })(window, document, "amazon_connect", windowId);
  amazon_connect("styles", {
    iconType: "VOICE",
    openChat: { color: "#ffffff", backgroundColor: "#123456" },
    closeChat: { color: "#ffffff", backgroundColor: "#123456" },
  });
  amazon_connect("webCallingCustomizationObject", {
    videoFilter: { backgroundBlur: { option: "ENABLED_OFF_BY_DEFAULT" } },
  });
  amazon_connect("snippetId", snippetId);
  amazon_connect("supportedMessagingContentTypes", [
    "text/plain",
    "text/markdown",
    "application/vnd.amazonaws.connect.message.interactive",
    "application/vnd.amazonaws.connect.message.interactive.response",
  ]);
};

// export const initUKTest = () => {
//   (function (w, d, x, id) {
//     var s = d.createElement("script");
//     // This url depends on the connect instance!
//     s.src = "https://d1ddlw7p7j6hta.cloudfront.net/amazon-connect-chat-interface-client.js";
//     s.async = 1;
//     s.id = id;
//     d.getElementsByTagName("head")[0].appendChild(s);
//     w[x] =
//       w[x] ||
//       function () {
//         (w[x].ac = w[x].ac || []).push(arguments);
//       };
//   })(window, document, "amazon_connect", "82080fbb-7833-4284-85d0-78676518967f");
//   amazon_connect("styles", {
//     iconType: "VOICE",
//     openChat: { color: "#ffffff", backgroundColor: "#123456" },
//     closeChat: { color: "#ffffff", backgroundColor: "#123456" },
//   });
//   amazon_connect(
//     "snippetId",
//     "QVFJREFIZ2ZySXd1eE1EWFNRRkRQSWFXZjRvaGxjT0xmd1pOWWViVU4vd0hnZnByS1FGc3BQRFBhTXVMOXJkMDlDSXduUDFTQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNbVlaYmxYVDRDNExUVU0vM0FnRVFnQ3ZlODcxOEk3NGlnV1M4cUZiWUU4cXBGTXpWZkx2UWdyWlgxQVNoZ0RFcitFMHdyQ1g4L1ZOSWJZMHY6OjlIZGU4K1dMalVCdmZsNGFZais5OHM1Q3NyL1MzdG9YeFhRQk5sWEllRHhldThqVkkrVzFCUWhUNFYzUndpQW1OemdYaWxYZXdlNElBek5ZU29QNWh0UE5vdCtzMDIzN1RWbnlURHpQeEFWZnRDZHFySGlDZWNZWlhZVFdxakg5Q3BJK1QwenJ5VG9hZm8rODZPTWJPc0ZVK3JKd0YyZz0="
//   );
//   amazon_connect("supportedMessagingContentTypes", [
//     "text/plain",
//     "text/markdown",
//     "application/vnd.amazonaws.connect.message.interactive",
//     "application/vnd.amazonaws.connect.message.interactive.response",
//   ]);
//   // Proof of concept to send the attributes
//   amazon_connect("contactAttributes", {
//     languageCode: "en", // from https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes
//     countryCode: "GB", // from https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
//   });
// };

export const initializeAmazonConnectWithCredential = ({
  connectSourceUrl,
  elementId,
  snippetId,
  contactAttributes,
}) => {
  (function (w, d, x, id) {
    var s = d.createElement("script");
    s.src = connectSourceUrl;
    s.async = 1;
    s.id = id;
    d.getElementsByTagName("head")[0].appendChild(s);
    w[x] =
      w[x] ||
      function () {
        (w[x].ac = w[x].ac || []).push(arguments);
      };
  })(window, document, "amazon_connect", elementId);
  amazon_connect("styles", {
    iconType: "VOICE",
    openChat: { color: "#ffffff", backgroundColor: "#123456" },
    closeChat: { color: "#ffffff", backgroundColor: "#123456" },
  });
  amazon_connect("webCallingCustomizationObject", {
    videoFilter: { backgroundBlur: { option: "ENABLED_OFF_BY_DEFAULT" } },
  });
  amazon_connect("snippetId", snippetId);
  amazon_connect("supportedMessagingContentTypes", [
    "text/plain",
    "text/markdown",
    "application/vnd.amazonaws.connect.message.interactive",
    "application/vnd.amazonaws.connect.message.interactive.response",
  ]);
  amazon_connect("customLaunchBehavior", {
    skipIconButtonAndAutoLaunch: true,
    alwaysHideWidgetButton: true,
  });
  amazon_connect("authenticate", function (callback) {
    callback(contactAttributes);
  });
};
