import { Button, ButtonSize } from "@amzn/stencil-react-components/dist/submodules/button";
import { P, Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { FlyoutContent, WithFlyout } from "@amzn/stencil-react-components/flyout";
import { Flex, View } from "@amzn/stencil-react-components/layout";
import DOMPurify from "dompurify";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getLocale } from "src/config/locale-config";
import { MetricsWindow, addEventMetric } from "src/dependencies/adobe-analytic";
import { AppointmentDetails } from "./AppointmentDetails";

const eventPageLoad = "page load of appointment details";
const eventOpenFlyout = "click of “View appointment details” button on connect widget page";

const AppointmentDetailsFlyoutContent = ({ agendaPublicText }: AppointmentDetails) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { t } = useTranslation();

  const locale = getLocale();

  useEffect(() => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventPageLoad);
  }, []);

  const purifiedHtml = agendaPublicText && DOMPurify.sanitize(agendaPublicText);

  const renderFlyout = ({ close }: { close: () => void }) =>
    purifiedHtml ? (
      <FlyoutContent titleText={t("VNHE-AppointmentDetails-Flyout-Title")} onCloseButtonClick={close}>
        <Text fontSize="T200" dangerouslySetInnerHTML={{ __html: purifiedHtml }}></Text>
      </FlyoutContent>
    ) : (
      <FlyoutContent titleText={t("VNHE-AppointmentDetails-Flyout-Title")} onCloseButtonClick={close}>
        <P>
          {t("VNHE-AppointmentDetails-Flyout-Description1")}
          <b style={{ padding: "0 5px" }}>{t("VNHE-AppointmentDetails-Flyout-Description2")}</b>
          {t("VNHE-AppointmentDetails-Flyout-Description3")}
        </P>
        <P>{t("VNHE-AppointmentDetails-Flyout-Description4")}</P>
        <ul style={{ paddingLeft: 5 }}>
          <li>
            <Text>{t("VNHE-AppointmentDetails-Flyout-ListItem0")}</Text>
          </li>
          <li>
            <Text>{t("VNHE-AppointmentDetails-Flyout-ListItem1")}</Text>
          </li>
          <li>
            <Text>{t("VNHE-AppointmentDetails-Flyout-ListItem2")}</Text>
          </li>
          <li>
            <Text>{t("VNHE-AppointmentDetails-Flyout-ListItem3")}</Text>
          </li>
          <li>
            <Text>{t("VNHE-AppointmentDetails-Flyout-ListItem4")}</Text>
          </li>
          <li>
            <Text>{t("VNHE-AppointmentDetails-Flyout-ListItem5")}</Text>
          </li>
        </ul>
      </FlyoutContent>
    );

  return (
    <View id="agenda-container" data-testid="agenda-container">
      <WithFlyout renderFlyout={renderFlyout}>
        {({ open }) => (
          <Flex flexDirection="column" gridGap="S200" justifyContent="center" alignItems="center">
            <Button
              size={ButtonSize.Small}
              onClick={() => {
                open();
                addEventMetric(window as MetricsWindow, applicationId!, locale, eventOpenFlyout);
              }}
            >
              {t("VNHE-AppointmentDetails-Flyout-ButtonLabel")}
            </Button>
            <span hidden id="sroDialog">
              has pop-up dialog
            </span>
          </Flex>
        )}
      </WithFlyout>
    </View>
  );
};

export default AppointmentDetailsFlyoutContent;
