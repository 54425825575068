import React, { useEffect, useRef } from "react";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { H1, Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/dist/submodules/button";
import { Card } from "@amzn/stencil-react-components/card";
import { Link } from "@amzn/stencil-react-components/link";
import Bg3 from "../../assets/bg3.svg";
import { QRCodeSVG } from "qrcode.react";
import { useRvpLoginUrl } from "src/hooks/useUrl";

interface RVPLandingPageProps {
  onContinue: () => void;
}

export const RVPLandingPage = ({ onContinue }: RVPLandingPageProps) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const { t } = useTranslation();
  const rvpLoginUrl = useRvpLoginUrl();

  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
  }, []);

  return (
    <>
      <Col padding="40px" gridGap="S300" display="flex" width={"100%"}>
        <H1 id="page-title" ref={titleRef} tabIndex={-1} style={{ fontSize: 24, lineHeight: 1.5 }}>
          {t("RVP-Landing-Title")}
        </H1>
        <Text fontSize="T200">{t("RVP-Landing-Description")}</Text>
        <Card>
          <Row gridGap="S300">
            <Col flexGrow={1}>
              <QRCodeSVG value={rvpLoginUrl} aria-hidden="true" />
            </Col>
            <ol>
              <Text>
                <li>{`${t("RVP-Landing-Instruction-1-1")} ${rvpLoginUrl} ${t("RVP-Landing-Instruction-1-2")}`}</li>
              </Text>
              <Text>
                <li>{t("RVP-Landing-Instruction-2")}</li>
              </Text>
              <Text>
                <li>{t("RVP-Landing-Instruction-3")}</li>
              </Text>
              <Text>
                <li>{t("RVP-Landing-Instruction-4")}</li>
              </Text>
            </ol>
          </Row>
        </Card>
        <Text>
          {t("RVP-Landing-I9-1")} <Link display="inline">{t("RVP-Landing-I9-2")}</Link> {t("RVP-Landing-I9-3")}
        </Text>
        <Button data-testid="rvp-landing-button" variant={ButtonVariant.Primary} onClick={onContinue}>
          {t("RVP-Landing-Button")}
        </Button>
      </Col>
      <Col width="100%" style={{ position: "absolute", left: 0, bottom: 0, zIndex: "-1" }}>
        <img src={Bg3} alt=""/>
      </Col>
    </>
  );
};
