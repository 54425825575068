import React from "react";
import { Flex, View } from "@amzn/stencil-react-components/dist/submodules/layout";
import { useCheckViewport } from "src/hooks/useCheckViewport";

interface PageWithIconProps {
  src: string;
  children: React.ReactNode;
  iconMobileFullwidth?: boolean;
}

const PageWithIcon = ({ children, src }: PageWithIconProps) => {
  const { isSmallViewport, isMediumViewport } = useCheckViewport();

  if (isSmallViewport || isMediumViewport) {
    return (
      <Flex
        flexDirection="column"
        style={{ position: "relative", height: "100vh", padding: 10 }}
        data-testid="page_with_icon-mobile"
      >
        <View>{children}</View>
        <View>
          <img src={src} style={{ width: "80%" }} alt="" />
        </View>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-around"
      height={"100vh"}
      style={{
        textAlign: "center",
      }}
      data-testid="page_with_icon-desktop"
    >
      <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{children}</View>
      <View style={{ display: "flex", justifyContent: "start" }}>
        <img src={src} alt="" />
      </View>
    </Flex>
  );
};

export default PageWithIcon;
