import React from "react";
import amazonLogo from "../../assets/amazon-logo.png";
import { useTranslation } from "react-i18next";
import { Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { Flex } from "@amzn/stencil-react-components/layout";
import { MainWithSkipLink } from "@amzn/stencil-react-components/a11y";

interface HeaderProps {
  children: React.ReactNode;
}

export const Header = ({ children }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <header style={{ zIndex: 100 }}>
        <Flex flexDirection="row">
          <img src={amazonLogo} width={150} alt="Amazon Jobs" />
          <Text
            style={{ marginLeft: 10, lineHeight: 1.8 }}
            data-testId='vnhe-header-title'
          >
            {t("VNHE-Header-Title")}
          </Text>
        </Flex>
      </header>
      <MainWithSkipLink targetId="page-title">{children}</MainWithSkipLink>
    </>
  );
};
