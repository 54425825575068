import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { hasQueryParam } from "src/utils/urls";
import AppointmentDetailsFlyoutContent from "./AppointmentDetailsFlyoutContent";

export const getClassNamesStartingWith = (classNames: string, prefix: string) => {
  const regex = new RegExp(`${prefix}-\\S*(?=\\s|$)`, "g");
  return classNames.split(" ").flatMap((className) => {
    const matches = className.match(regex);
    return matches ? matches : [];
  });
};

export const getElementByClassNamePrefix = (prefix: string) => {
  const classNames = Array.from(document.querySelectorAll(`[class*="${prefix}"]`))
    .flatMap((element) => Array.from(element.classList))
    .join(" ");

  const matchingClassNames = getClassNamesStartingWith(classNames, prefix);
  const elements: HTMLElement[] = [];

  for (const className of matchingClassNames) {
    const elementsWithClass = Array.from(document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>);
    elements.push(...elementsWithClass);
  }

  return elements;
};

export const AMAZON_CONNECT_CONTAINER_ID = "amazon-connect-chat-widget";
export const AMAZON_CONNECT_INNER_CONTAINER_ID = "acFrameContainer";
export const AMAZON_CONNECT_JOIN_BUTTON_ID = "acOpenButton";
export const AMAZON_CONNECT_IFRAME_ID = "amazon-connect-chat-widget-iframe";

export interface AppointmentDetails {
  agendaPublicText?: string;
}

export const AppointmentDetails = ({ agendaPublicText }: AppointmentDetails) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const amazonConnectContainerCheckInterval = setInterval(() => {
      const container = document.getElementById(AMAZON_CONNECT_CONTAINER_ID);
      if (container) {
        setShouldRender(true);
        clearInterval(amazonConnectContainerCheckInterval);
      }
    }, 200);

    return () => clearInterval(amazonConnectContainerCheckInterval);
  }, []);

  useEffect(() => {
    if (shouldRender) {
      const amazonConnectContainerCheckOpenenedInterval = setInterval(() => {
        if (!document.getElementById(AMAZON_CONNECT_IFRAME_ID)) {
          document.getElementById("agenda-container")!.style.display = "none";

          if (hasQueryParam("bypasscorp")) {
            window.location.href = `${window.location.href}&call-ended=true`;
          } else {
            window.location.href = `${window.location.href}?call-ended=true`;
          }
          clearInterval(amazonConnectContainerCheckOpenenedInterval);
        }
      }, 50);

      return () => clearInterval(amazonConnectContainerCheckOpenenedInterval);
    }
  }, [shouldRender]);

  const getWidgetBaseDOM = () => {
    const amazonConnectContainer = getElementByClassNamePrefix(AMAZON_CONNECT_INNER_CONTAINER_ID);
    const res = process.env.NODE_ENV === "development" ? amazonConnectContainer?.[1] : amazonConnectContainer?.[0];

    return res;
  };

  if (shouldRender) {
    const amazonConnectContainer = getWidgetBaseDOM();
    return (
      <>
        {shouldRender &&
          amazonConnectContainer &&
          createPortal(<AppointmentDetailsFlyoutContent agendaPublicText={agendaPublicText} />, amazonConnectContainer)}
      </>
    );
  }

  return null;
};
