import { ConnectQueueFragment } from "./ConnectQueueFragment";

const App = () => {
  return (
    <div className={"ConnectWidgetInitializer"}>
      <title>Video Appt </title>
      <ConnectQueueFragment />
    </div>
  );
};

export default App;
