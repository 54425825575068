import { has } from '../../shared/utils';
// this import is mocked when building for the asset loader
import '../../metrics/register-component-metrics';
import { coerceToBoolean } from './type-utils';

export class KatalComponent extends HTMLElement {
  [key: string]: any; //index signature for typescript. property upgrade logic won't compile without this.

  private _propertiesHaveBeenUpgraded = false;
  private _observers = [];
  private _pendingObserverCallbacks = [];

  constructor(private _observedAttributes: string[]) {
    super();
  }

  connectedCallback() {
    window.dispatchEvent(
      new CustomEvent('katal-component-connected', {
        detail: { element: this },
      })
    );

    this.upgradePropertiesToAttributesOnce(this._observedAttributes);
  }

  disconnectedCallback() {
    this._observers.forEach(ob => {
      ob.disconnect();
    });
  }

  normalizeAttributeName(attributeName: string) {
    // 'xxx' stays 'xxx'
    // 'xxx-yyy' becomes 'xxxYyy'
    // 'xxx-yyy-zzz' becomes 'xxxYyyZzz'
    const propertyName = attributeName
      .split('-')
      .map((val, i) =>
        i === 0 ? val : val.charAt(0).toUpperCase() + val.slice(1)
      )
      .join('');
    return propertyName;
  }

  upgradePropertiesToAttributesOnce(attributeNames: string[]) {
    if (this.propertiesHaveBeenUpgraded) {
      return;
    }

    attributeNames.forEach(attributeName => {
      const propertyName = this.normalizeAttributeName(attributeName);

      if (has(this, propertyName)) {
        const value = this[propertyName];
        delete this[propertyName];
        this[propertyName] = value; // Calls the setter which sets attribute instead of property
      }
    });

    this._propertiesHaveBeenUpgraded = true;
  }

  protected get propertiesHaveBeenUpgraded() {
    return this._propertiesHaveBeenUpgraded;
  }

  removeOrSetAttribute(attributeName: string, value: any) {
    if (value == null) {
      this.removeAttribute(attributeName);
    } else {
      this.setAttribute(attributeName, value);
    }
  }

  getBooleanAttribute(attributeName: string) {
    return coerceToBoolean(this.getAttribute(attributeName));
  }

  setBooleanAttribute(attributeName: string, value: any) {
    value = coerceToBoolean(value);
    if (value) {
      this.setAttribute(attributeName, '');
    } else {
      this.removeAttribute(attributeName);
    }
  }

  getAttributeOrDefault(attributeName: string, defaultValue: string) {
    return this.getAttribute(attributeName) || defaultValue;
  }

  dispatchCustomEvent<T extends Record<string, unknown>>(
    eventType: string,
    detail?: T,
    bubbles: Omit<CustomEventInit, 'detail'> | boolean = true
  ) {
    const evt = new CustomEvent(eventType, {
      detail,
      ...(typeof bubbles === 'boolean' ? { bubbles } : bubbles),
    });
    this.dispatchEvent(evt);
  }

  observeChildren(
    parentElement: HTMLElement,
    callback: () => void
  ): MutationObserver {
    const observer = new MutationObserver(
      this._observerCalled.bind(this, parentElement, callback)
    );
    observer.observe(parentElement, { childList: true });
    this._observers.push(observer);
    return observer;
  }

  /**
   * De-dups changes to the element children list to avoid multiple re-renders.
   */
  _observerCalled(parentElement: HTMLElement, callback: () => void): void {
    if (this._pendingObserverCallbacks.length === 0) {
      window.requestAnimationFrame(() => {
        this._pendingObserverCallbacks.forEach(callback => callback.callback());
        this._pendingObserverCallbacks = [];
      });
      this._pendingObserverCallbacks.push({ parentElement, callback });
    } else {
      const alreadyHasCallback = this._pendingObserverCallbacks.some(
        callback => callback.parentElement === parentElement
      );
      if (!alreadyHasCallback) {
        this._pendingObserverCallbacks.push({ parentElement, callback });
      }
    }
  }
}
