import { useEffect, useState } from "react";
import { hasQueryParam, isValidAppId, isValidAppointmentType } from "src/utils/urls";
import { dispatchOnDev } from "src/utils/dev-env-helper";
import { AppointmentType, ConnectWidgetData, ConnectWidgetErrorData } from "src/components/types";
import { apiClient } from "src/dependencies/api-client";
import { useParams } from "react-router-dom";
import { getLocale } from "src/config/locale-config";

export const useWidgetData = (rvp: boolean = false) => {
  const [isFetching, setIsFetching] = useState(true);
  const [widgetData, setWidgetData] = useState<ConnectWidgetData>();
  const [error, setError] = useState<ConnectWidgetErrorData | null>(null);
  const [isCallEnded, setIsCallEnded] = useState(false);
  const [nonWidgetErr, setNonWidgetErr] = useState<Error | null>(null);
  const { applicationId, appointmentType } = useParams<{ applicationId: string; appointmentType: AppointmentType }>();
  const locale = getLocale();

  const getWidgetData = async (applicationId: string, appointmentType: string, locale: string) => {
    await apiClient
      .getWidgetData({ applicationId, appointmentType, locale, rvp })
      .then((widgetData) => {
        setWidgetData;
        if (widgetData.error) {
          setError(widgetData.error);
        } else {
          if (widgetData.data) {
            setWidgetData(widgetData.data);
          }
        }
      })
      .catch((err: Error) => {
        console.log(error);
        setNonWidgetErr(err);
        dispatchOnDev(() => console.error(`Error fetching widgetData: ${err}`));
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (applicationId && applicationId.length && appointmentType) {
      if (!isValidAppointmentType(appointmentType) || !isValidAppId(applicationId)) {
        setNonWidgetErr(new Error(`Invalid application request: ${window.location.href}`));
        dispatchOnDev(() => console.error(`Invalid application request: ${window.location.href}`));
      }

      if (hasQueryParam("call-ended")) {
        setIsCallEnded(true);
      } else {
        getWidgetData(applicationId, appointmentType, locale);
      }
    }
  }, [applicationId, appointmentType, locale]);

  return { isFetching, widgetData, error, isCallEnded, nonWidgetErr };
};
