import React, { useEffect, useRef } from "react";
import { View } from "@amzn/stencil-react-components/dist/submodules/layout";
import { useCheckViewport } from "src/hooks/useCheckViewport";

interface VideoProps {
  stream: MediaStream | undefined;
  selectedVideoDevice: string;
}

const Video = ({ stream, selectedVideoDevice }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isSmallViewport } = useCheckViewport();

  useEffect(() => {
    if (stream && selectedVideoDevice && videoRef.current) attachVideoStream(stream, videoRef.current);
  }, [stream, selectedVideoDevice, videoRef.current]);

  function attachVideoStream(stream: MediaStream, videoElement: HTMLVideoElement) {
    if (videoElement) {
      // attach video source from MediaStream | https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/srcObject
      videoElement.srcObject = stream;
    }
  }

  return (
    <View
      style={
        isSmallViewport
          ? {
              width: "100%",
              marginBottom: 20,
              backgroundColor: "black",
            }
          : { width: "100%", height: "400px", marginBottom: 20, backgroundColor: "black" }
      }
      data-testid="video-container"
    >
      <video ref={videoRef} autoPlay height={"400px"} width={"100%"} muted data-testid="video-vnhe" playsInline></video>
    </View>
  );
};

export default Video;
